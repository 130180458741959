<template>

  <div class="container col-xxl-8 px-4 py-5 marketing">

    <!-- Three columns of text below the carousel -->

    <div class="row">
      <div class="col-lg-12 local-header">
          <h2>Ayuda</h2>
          <p>Avatares produce NFTs que además de ser coleccionables digitales, proveen acceso a contenido exclusivo,
              sorteos y otros eventos. Las colecciones están 100% descentralizadas (los
              NFTs y contratos inteligentes se ejecutan en la red Polygon, y el arte y la metadata están almacenados
              en <a href="https://ipfs.io" target="_blank">IPFS</a>). Avatares fue creado y es operado por un 
              equipo entusiasta del arte y la tecnología con bases en Buenos Aires, Argentina y San José, California.
              La misión de Avatares es amplificar
              la relación artista/audiencia en el mundo de habla hispana mediante instrumentos tecnológicos (NFTs).  
          </p>
      </div>  
    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="icon-square text-dark flex-shrink-0">
          <i class="far fa-file-image local-item"></i>
        </div>
        <h3>NFTs</h3>
        <p>Informate acerca de lo que son los NFTs y como el blockchain ha permitido la creacion de los mismos junto con las criptomonedas.</p>
        <p>
            <a class="btn btn-primary" href="/ayuda/nft">Leer &raquo;</a>
        </p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <div class="icon-square text-dark flex-shrink-0">
            <i class="fas fa-shopping-cart local-item"></i>
        </div>
        <h3>Comprando</h3>
        <p>Aca te indicamos paso a paso como podés comprar avatares desde la creación de la billetera hasta la compra de los mismos.</p>
        <p>
            <a class="btn btn-primary" href="/ayuda/comprarmovil">Móvil &raquo;</a> &nbsp;
            <a class="btn btn-primary" href="/ayuda/compraresc">PC &raquo;</a>
        </p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <div class="icon-square text-dark flex-shrink-0">
            <i class="fas fa-ticket-alt local-item"></i>
        </div>
        <h3>Acceso VIP</h3>
        <p>Una vez comprado el NFT, podés acceder al club VIP en donde podrás ver contenido exclusivo y participar de sorteos y otros eventos.</p>
        <p>
            <a class="btn btn-primary" href="/ayuda/clubmovil">Móvil &raquo;</a> &nbsp;
            <a class="btn btn-primary" href="/ayuda/clubesc">PC &raquo;</a>
        </p>
      </div><!-- /.col-lg-4 -->
      <!-- <div class="col-lg-3">
        <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text></svg>

        <h3>Preguntas</h3>
        <p>Aqui les ponemos las preguntas que mas nos hacen acerca de Avatares y por supuesto las respuestas y sugerencias.</p>
        <p><a class="btn btn-success" href="#">Leer &raquo;</a></p>
      </div> -->



    </div><!-- /.row -->


  </div>

  <!-- <div class="container marketing">
    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">First featurette heading. <span class="text-muted">It’ll blow your mind.</span></h2>
        <p class="lead">Some great placeholder content for the first featurette here. Imagine some exciting prose here.</p>
      </div>
      <div class="col-md-5">
        <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#eee"/><text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text></svg>

      </div>
    </div>

  </div> -->





</template>

<script>
export default {
    name: 'Help',
    data() {
        return {}
    },
    mounted() {
        console.log("[home] mounting");
    },

    methods: {

}
}
</script>

<style scoped>
.wrapper {
  padding-bottom: 3rem;
}

.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* rtl:end:ignore */

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 1rem 0; /* Space out the Bootstrap <hr> more */
}

.featurette-divider-pos {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}


/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1.4;
  /* rtl:remove */
  letter-spacing: -.05rem;
}

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 2.4rem;
    line-height: 3rem;
  }

}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 5rem;
  }
}

.local-main-button {
  margin-bottom: 1rem;
}

.local-item {
  font-size: 4rem;
  color: #4db6ac;
  margin-bottom: 1.5rem;
}

.local-header {
  margin-bottom: 3rem;
}

</style>
