import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/Home.vue';
import AvatarHome from '../pages/AvatarHome.vue';
import Collection from '../pages/Collection.vue';
import Profile from '../pages/Profile.vue';
import ProfileEdit from '../pages/ProfileEdit.vue';
import ProfileAvatar from '../pages/ProfileAvatar.vue';
import VIP from '../pages/VIP.vue';
import VIPall from '../pages/VIPall.vue';
import Help from '../pages/Help.vue';
import HelpBuyMob from '../pages/HelpBuyMob.vue';
import HelpBuyDesk from '../pages/HelpBuyDesk.vue';
import HelpClubEsc from '../pages/HelpClubEsc.vue';
import HelpClubMob from '../pages/HelpClubMob.vue';
import HelpNFT from '../pages/HelpNFT.vue';
import Login from '../pages/Login.vue';
import Logout from '../pages/Logout.vue';

import About from '../pages/About.vue';
import Terms from '../pages/Terms.vue';
import Privacy from '../pages/Privacy.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/perfil/avatar',
        name: 'ProfileAvatar',
        component: ProfileAvatar
    },
    {
        path: '/perfil/editar',
        name: 'ProfileEdit',
        component: ProfileEdit
    },
    {
        path: '/vip',
        name: 'VIPAll',
        component: VIPall
    },
    {
        path: '/tyc',
        name: 'Tyc',
        component: Terms
    },
    {
        path: '/privacidad',
        name: 'Privacidad',
        component: Privacy
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/ayuda/compraresc',
        name: 'Ayuda Comprar Desktop',
        component: HelpBuyDesk
    },
    {
        path: '/ayuda/comprarmovil',
        name: 'Ayuda Comprar Movil',
        component: HelpBuyMob
    },
    {
        path: '/ayuda/clubesc',
        name: 'Ayuda Club Desktop',
        component: HelpClubEsc
    },
    {
        path: '/ayuda/clubmovil',
        name: 'Ayuda Club Mobil',
        component: HelpClubMob
    },
    {
        path: '/ayuda/nft',
        name: 'Ayuda NFT',
        component: HelpNFT
    },
    {
        path: '/ayuda',
        name: 'Ayuda',
        component: Help
    },
    {
        path: '/acerca',
        name: 'Acerca',
        component: About
    },


    {
        path: '/@:username',
        name: 'Profile',
        component: Profile
    },    
    // {
    //     path: '/perfil',
    //     name: 'Profile',
    //     component: Profile
    // },
    {
        path: '/:avatar/:collection/vip',
        name: 'VIP',
        component: VIP
    },
    {
        path: '/:avatar/:collection',
        name: 'Collection',
        component: Collection
    },
    {
        path: '/:avatar',
        name: 'AvatarHome',
        component: AvatarHome
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
