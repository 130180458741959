<template>

<div v-show="loading">
    <div class="d-flex justify-content-center local-loader">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>

<div class="wrapper" v-show="loaded">
    <div id="content-page" class="content-page">
        <div class="container">

            <div class="row">
                <div class="col-lg-4">
                    <div class="card">
                        <div class="top-bg-image">
                            <!-- <img src="@/assets/images/page-img/56.jpg" class="img-fluid" alt="Responsive image"> -->
                            <img src="@/assets/images/profile_bk1.png" class="img-fluid" alt="Responsive image">

                        </div>
                        <div class="card-body text-center">
                        <div class="group-icon">
                            <img v-bind:src="avatar" alt="profile-img" class="avatar-130 img-fluid local-round">
                        </div>
                        <h5 class="text-center local-username">{{ usernameUrl }}</h5>
                        <div>{{ walletAbb }}</div>
                        <div class="local-list-wrapper">
                            <ul>
                                <li v-show="twitter">
                                    <a v-bind:href="twitter" target="_blank">
                                        <i class="fab fa-twitter local-icon"></i>
                                    </a>
                                </li>
                                <li v-show="instagram">
                                    <a v-bind:href="instagram" target="_blank">
                                        <i class="fab fa-instagram local-icon"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <button v-show="isUser" class="btn btn-primary me-2 local-edit-button" v-on:click="edit">Editar</button>
                        <button v-show="(isUser && avatars.length)" class="btn btn-primary me-2 local-edit-button" v-on:click="vip">Acceso VIP</button>

                        <!-- <a v-on:click="logout" class="local-logout">Desconectar</a> -->

                    </div>
                    </div>
                </div>
                <div class="col-lg-8">
                        <div class="row">

                            <div class="col-lg-6" v-for="a in avatars" :key="a.id">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="event-images position-relative">
                                            <a v-bind:href="a.image_cache" download target="_blank">    
                                                <img v-bind:src="a.image_cache" class="img-fluid" alt="Imagen">
                                            </a>
                                        </div>
                                        <div class="mt-1">
                                            <h5>{{ a.title }}</h5>
                                        </div>
                                        <!-- <div class="local-description">
                                            <small>{{ a.description }}</small>
                                        </div> -->

                                        <div class="mt-1" v-show="isUser">
                                            <small>Cantidad: {{ a.quantity }}</small>
                                        </div>

                                        <hr class="hr-horizontal">
                                        <div class="justify-content-between" v-show="isUser">

                                            <div>
                                                <a class="local-link" v-on:click="blockchain(a)">
                                                    <i class="fas fa-link"></i>
                                                    <small class="ms-1">Blockchain</small>
                                                </a>
                                            </div>
                                            <div>
                                                <a class="local-link" v-on:click="changeAva(a)">
                                                    <i class="fas fa-user-circle"></i>
                                                    <small class="ms-1">Usar como Avatar</small>
                                                </a>
                                            </div>
                                            <div>
                                                <a class="local-link" v-on:click="opensea(a)">
                                                    <i class="fa-solid fa-sailboat"></i>
                                                    <small class="ms-1">Ver en Opensea</small>
                                                </a>
                                            </div>

                                            <div>
                                                <a class="local-link" v-on:click="redir(a, false)">
                                                    <i class="fas fa-shopping-cart"></i>
                                                    <small class="ms-1">Comprar otro</small>
                                                </a>
                                            </div>
                                            <!-- <div>
                                                <a class="local-link" v-on:click="redir(a, true)">
                                                    <i class="fas fa-unlock"></i>
                                                    <small class="ms-1">Acceso VIP</small>
                                                </a>
                                            </div> -->
                                        </div>

                                        <div class="justify-content-between" v-show="!isUser">
                                            <div>
                                                <a class="local-link" v-on:click="redir(a, false)">
                                                    <i class="fas fa-shopping-cart"></i>
                                                    <small class="ms-1">Comprar de esta Colección</small>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

            </div>

        </div>
    </div>
</div>


<div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11" v-show="toast">
  <div id="liveToast" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <small class="me-auto"></small>
      <small></small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
        <div class="spinner-border spinner-border-sm text-primary local-toast-loader" role="status" v-if="toastLoader">
            <span class="visually-hidden">Loading...</span>
        </div>
        <i v-else class="far fa-check-circle text-success local-toast-loader"></i>
        {{ toastMessage }}
    </div>
  </div>
</div>

  <!-- <div class="profile">
    <div>

        <div>{{ username }}</div>
        <div>{{ email }}</div>

        <button v-on:click="edit">Editar</button>

        <img v-bind:src="avatar" />
        <button>Cambiar</button>

    </div>

    <div>
        <h3>Mis Avatares</h3>

        <div v-for="a in avatars" :key="a.id">
            <h4>{{ a.title }} <span v-if="a.quantity">(x{{ a.quantity }})</span></h4>
            <a v-bind:href="a.image_cache" download target="_blank">
                <img style="width: 300px" v-bind:src="a.image_cache" />
            </a>
            <div>{{ a.description }}</div>
            <button v-on:click="changeAva(a)">Usar como Avatar</button>
            <button v-on:click="redir(a, true)">Acceso VIP</button>
            <button v-on:click="redir(a, false)">Comprar Otro</button>

        </div>


    </div>

  </div> -->

</template>

<script>
import CollectionService from "@/services/CollectionService";
import FirebaseService from "@/services/FirebaseService";
import Web3Service from "@/services/Web3Service";

const OPENSEA = "https://testnets.opensea.io/assets/mumbai/";

export default {
    name: "Profile",
    data() {
        return {
            isUser: 0,  // if logged user is same
            userUrl: null,  //  url user object
            usernameUrl: "",  // url username
            user: null,  // logged user object
            username: "",  // logged username
            twitter: "",
            instagram: "",
            avatar: "https://firebasestorage.googleapis.com/v0/b/avatares-io.appspot.com/o/avataresv2.png?alt=media&token=066f275d-ffbf-4e49-9f92-0c1ae1d2bf2c",
            firebase: null,
            wallet: null,  // connected/logged wallet
            mode: null,
            source: null,
            avatars: [],
            exchange: null,
            walletAbb: "",  // url user wallet
            loading: true,
            loaded: false,
            toast: false,
            toastMessage: "",
            toastLoader: false
        }
    },

    async mounted() {
        console.log("[profile] mounting");

        // Init
        this.firebase = new FirebaseService();
        this.usernameUrl = this.$route.params.username.toLowerCase();
        console.log("[profile] provided username", this.usernameUrl);
        // look for user
        this.userUrl = await this.firebase.getProfile("username", this.usernameUrl);
        if (!this.userUrl) {
            this.$swal({
                icon: "error",
                title: "@" + this.usernameUrl + " no existe!",
                text: "",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
            });
            this.$router.push("/");
        }
        this.walletAbb = Web3Service.dApp.abbreviateWallet(this.userUrl.wallet);
        // see if it's the same
        let isCon = await Web3Service.dApp.isConnected();
        if (isCon) {
            this.wallet = Web3Service.dApp.address;
            this.user = await this.firebase.getProfile("wallet", this.wallet);
            if (this.user.username == this.userUrl.username) {
                // logged user is the same!
                this.isUser = 1;
            }
        } 
        // else {
        //     this.user = await this.firebase.getProfile("username", this.usernameUrl);
        //     if (!this.user) {
        //         alert("Does not exist!!");
        //     }
        //     this.wallet = this.user.wallet;
        // }





        // if (isCon) {
        //     this.wallet = Web3Service.dApp.address;
        //     this.user = await this.firebase.getProfile("wallet", this.wallet);
        //     this.isUser = 1;
        // } else {
        //     this.user = await this.firebase.getProfile("username", this.usernameUrl);
        //     if (!this.user) {
        //         alert("Does not exist!!");
        //     }
        //     this.wallet = this.user.wallet;
        // }
        // this.walletAbb = Web3Service.dApp.abbreviateWallet(this.wallet);
        // Load up page
        await this.info();
        await this.avatares();

        // test
//         this.firebase.resetPassword("marchie@gmail.com");


    },

    methods: {

        async info() {
            if (this.userUrl.avatar) {
                this.avatar = this.userUrl.avatar;
            }
            if (this.userUrl.twitter) {
                this.twitter = "https://twitter.com/" + this.userUrl.twitter;
            }
            if (this.userUrl.instagram) {
                this.instagram = "https://instagram.com/" + this.userUrl.instagram;
            }
        },

        async avatares() {
            // Grab from exchange
            this.loadExchangeContract();
            this.exchange.methods.balanceOfWallet(this.userUrl.wallet).call()
                .then((result) => {
                    let allCollections = CollectionService.main.getAllCollections();
                    console.log("result", result, allCollections);
                    for(let i=0; i < result.length; i++) {
                        let elem = parseInt(result[i]);
                        if (elem) {
                            let info = allCollections[i];
                            info["quantity"] = elem;
                            this.avatars.push(info);
                        }
                    }
                    this.loading = false;
                    this.loaded = true;
                }).catch((error) => {
                    console.log(error);
                });
        },

        loadExchangeContract() {
            this.exchange = new Web3Service.dApp.roWeb3.eth.Contract(
                Web3Service.dApp.exchangeAbi, Web3Service.dApp.exchangeAddress
            );
        },

        redir(collection, vip) {
            let collectionSet = CollectionService.main.getCollectionSetByIndex(collection.collectionSetId);
            let url = "/" + collectionSet.avatar + "/" + collectionSet.slug;
            if (vip) {
                url = url + "/vip";
            }
            window.location = url;
        },

        async changeAva(collection) {
            this.toast = true;
            this.toastLoader = true;
            this.toastMessage = "Cambiando tu Avatar ...";
            await this.firebase.updateAvatar(collection.image_cache);
            this.avatar = collection.image_cache;
            this.emitter.emit("header:update:avatar", collection.image_cache);
            this.toastMessage = "Avatar cambiado!";
            this.toastLoader = false;
        },

        edit() {
            let url = "/perfil/editar?source=/@" + this.usernameUrl;
            this.$router.push(url);
        },

        vip() {
            this.$router.push("/vip");
        },

        blockchain(collection) {
            console.log(collection);

            let html = `
                <table class="table local-table">
                <tbody style="font-size: 0.8rem">
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Cadena</th>
                        <td style="text-align: left;">
                            <a href="https://polygon.technology" target="_blank">
                                Polygon
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Dirección</th>
                        <td style="text-align: left;">` + Web3Service.dApp.exchangeAddress + `</td>
                    </tr>
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Clase</th>
                        <td style="text-align: left;">` + collection.id + `</td>
                    </tr>
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Metadata</th>
                        <td style="text-align: left;">ipfs://:` + Web3Service.dApp.metadata + `</td>
                    </tr>
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Media</th>
                        <td style="text-align: left;">` + collection.image + `</td>
                    </tr>

                </tbody>
                </table>
            `;

            this.$swal({
                // iconHtml: '<img src="https://picsum.photos/100/100">',
                // customClass: {
                //     icon: 'no-border'
                // },
                title: collection.title,
                html: html,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
            });

        },

        opensea(collection) {
            let url = OPENSEA + Web3Service.dApp.factoryAddress + "/" + String(collection.id);
            window.open(url);
        },

        async logout() {
            this.firebase.signout();
        }


    }
}
</script>

<style scoped>
.text {
  width: 50%;
  border: 1px solid #ccc;
  height: 20px;
  padding: 12px 20px;
  margin: 4px;
  border-radius: 4px;
}

.profile {
  display: list-item;
  text-align: center;
}

.button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.local-round {
  border-radius: 50%;
}

.local-username {
  margin-top: 1rem;
}

.local-icon {
  font-size: 1.5rem;
}

.local-list-wrapper {
  text-align: center;
  margin-top: 0.5rem;
}

.local-list-wrapper ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  /* For IE, the outcast */
  zoom:1;
  *display: inline;
  list-style: none;
}

.local-list-wrapper li {
  float: left;
  padding-right: 1rem !important;
}

.local-edit-button {
  margin-top: 0.8rem;
}

.local-link {
  cursor: pointer;
}

.local-link i {
  width: 1.2rem;
}

.local-description {
  max-height: 5rem;
  overflow-y: auto;
}

.local-table {
  font-size: 0.8rem;
}

.local-loader {
  margin-top: 2rem;
}

.local-toast-loader {
  margin-right: 0.3rem;
}

.wrapper {
  padding-bottom: 3rem;
}

.local-logout {
  font-size: 0.7rem;
  margin-top: 1rem;
  display: block;
  cursor: pointer;
}
</style>