<template>

<div v-show="loading">
    <div class="d-flex justify-content-center local-loader">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>

<div class="wrapper" v-show="loaded">
    <div id="content-page" class="content-page">
        <div class="container">
            <div class="row">

                <div class="card-body p-0" v-show="buySection"> <!-- buySection -->
                    <div class="row">
                    <div class="col-lg-6">
                        <div class="image-block mt-3">
                            <img v-bind:src="prebuyImage" class="img-fluid rounded w-100" alt="blog-img">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="blog-description shadow-none p-3 ">
                            <div class="date mb-1">
                                <a v-bind:href="'/' + avatarSlug">
                                Colección: {{ avatarSlug }}
                                </a>
                            </div>
                            <h2 class="mb-2">
                                {{ name }}
                                <i class="fas fa-check-circle local-badge"></i>                                
                            </h2>
                            <p>{{ description}}</p>                        

                            <div>
                                <a class="local-link" v-on:click="blockchain">
                                    <i class="fas fa-link"></i>
                                    <small class="ms-1">Información del Blockchain</small>
                                </a>
                            </div>

                            <h5>Precio: {{ priceLabel }} MATIC</h5>


                            <div class="group-smile mt-4 d-flex flex-wrap align-items-center justify-content-between">
                                <div class="iq-media-group">
                                    Disponibles: <span class="local-available">{{ available }}</span>
                                    <div class="local-countdown" v-show="showCountdown">Sale a la venta en {{ countdown }}</div>

                                </div>

                                <div class="comment">
                                    <button type="button" class="btn btn-primary me-2 w-100" :disabled="saleEnabled == 0" v-on:click="buy" v-show="buyButton">
                                        Comprar Avatar
                                    </button>

                                    <button class="btn btn-primary" type="button" disabled v-show="buying">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Comprando ...
                                    </button>

                                </div>

                            </div>


                            <div class="group-smile d-flex flex-wrap align-items-center justify-content-between local-tos">
                                <div class="iq-media-group">
                                </div>

                                <div class="form-check d-inline-block pt-1">
                                    <input type="checkbox" class="form-check-input local-checkbox" v-model="tos">
                                    <label class="local-form-check" for="customCheck11">Acepto los 
                                        <a href="/tyc" target="_blank">
                                            términos y cond.
                                        </a>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    </div>
                </div>

                <div class="card-body p-0" v-show="inProgressSection">  <!-- inProgressSection -->
                    <div class="row">
                    <div class="col-lg-12 card local-progress">
                        <div class="blog-description shadow-none p-3">

                            <div class="spinner-border text-primary local-bigspinner" role="status">
                                <span class="visually-hidden">cargando ...</span>
                            </div>

                            <div class="date mb-1">Estás comprando un Avatar NFT de la colección</div>
                            <h2 class="mb-2">{{ name }}</h2>

                            <img v-bind:src="prebuyImage" class="img-fluid rounded local-prebuy" alt="blog-img">

                            <h4>Precio: {{priceLabel}} MATIC</h4>

                            <div style="margin-top: 1.5rem;">
                                La transacción se está confirmando en el
                                <a target="_blank" v-bind:href="txLink">blockchain</a> ...
                            </div>

                        </div>
                    </div>
                    </div>
                </div>

                <div class="card-body p-0" v-show="purchaseDone"> <!-- purchaseDone -->
                    <div class="row">
                    <div class="col-lg-12 card local-progress">
                        <div class="blog-description shadow-none p-3">

                            <h5>Felicitaciones! Te tocó:</h5>
                            <h2 class="mb-2">{{ previewTitle }}</h2>

                            <div style="width: 100%; text-align: center;">
                                <img style="width: 60%; border-radius: 0.6rem" v-bind:src="previewUrl">
                            </div>

                            <div style="margin-top: 1rem; margin-bottom: 2rem;">{{ previewDesc }}</div>

                            <div class="justify-content-between">
                                <div class="local-line">
                                    <a class="local-link" v-on:click="goToProfile">
                                        <i class="fas fa-user-circle"></i>
                                        <small class="ms-1">Ir a Perfil</small>
                                    </a>
                                </div>

                                <!-- <div class="local-line">
                                    <a class="local-link" v-on:click="goToOS">
                                        <i class="fas fa-eye"></i>
                                        <small class="ms-1">Ver en Opensea</small>
                                    </a>
                                </div> -->


                                <div class="local-line">
                                    <a class="local-link" v-on:click="goToVIP">
                                        <i class="fas fa-unlock"></i>
                                        <small class="ms-1">Acceso VIP</small>
                                    </a>
                                </div>
                                <div class="local-line">
                                    <a class="local-link" v-on:click="goToBuy">
                                        <i class="fas fa-shopping-cart"></i>
                                        <small class="ms-1">Comprar otro</small>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

</template>

<script>
import CollectionService from "@/services/CollectionService";
import FirebaseService from "@/services/FirebaseService";
import Web3Service from "@/services/Web3Service";

const SCANNER = "https://mumbai.polygonscan.com/"
const OPENSEA = "https://testnets.opensea.io/assets/mumbai/";
var seconds;

export default {
    name: "Collection",
    data() {
        return {
            user: null,
            avatarSlug: null,
            collectionSetSlug: null,
            collectionSetId: -1,
            name: "",
            description: "",
            total: 0,
            available: 0,
            priceLabel: 0,
            priceDec: null,
            // Web3 related
            isConn: false,
            exchangeRo: null,  // Exchange contract read only
            exhange: null,  // Exchange contract from MM
            firebase: null,
            wallet: null,
            purchasedCollectionId: null,
            // countdown
            now: parseInt(Date.now() / 1000),
            // UI
            buySection: true,
            inProgressSection: false,
            purchaseDone: false,
            prebuyImage: "",
            previewTitle: "",
            previewUrl: "",
            previewDesc: "",
            txLink: "",
            buyButton: true,
            buying: false,
            tos: false,
            loading: true,
            loaded: false,
            countdown: "",
            saleEnabled: 0,
            showCountdown: true
        }
    },

    async mounted() {
        console.log("[collection] mounting");

        this.avatarSlug = this.$route.params.avatar;
        this.collectionSetSlug = this.$route.params.collection;
        console.log("avatarslug", this.avatarSlug);
        console.log("collectionsetslug", this.collectionSetSlug);
        if (!this.collectionSetSlug) {
            alert("redirect to 404!!!");
            return;
        }
        // look for collection
        this.collectionSets = CollectionService.main.getCollectionSets(this.avatarSlug);
        let collectionSet = null;
        for (var i=0; i < this.collectionSets.length; i++) {
            let elem = this.collectionSets[i];
            if (elem.slug == this.collectionSetSlug) {
                collectionSet = elem;
            }
        }
        if (!collectionSet) {
            alert("redirect to 404!!!");
            return;
        }
        console.log("CollectionSet", collectionSet);

        this.name = collectionSet.title;
        this.description = collectionSet.description;
        this.collectionSetId = collectionSet.collectionSetId;
        this.total = collectionSet.editions;
        this.prebuyImage = collectionSet.image;

        // test
        // collectionSet.starts = 1645846871;

        // Timer?
        if (this.now > collectionSet.starts) {
            this.showCountdown = false;
            this.saleEnabled = 1;
        } else {
            seconds = collectionSet.starts - this.now;  // prod
            // seconds = 10; // test
            this.countdownTimer = setInterval(this.timer, 1000);
        }

        await this.init();
        window.addEventListener("load", () => {
            this.loading = false;
            this.loaded = true;
        });
    },

    methods: {
        async init() {
            this.loadExchangeContractRo();
            await this.checkAvailable();
            await this.getPrice();
            console.log("price", this.priceLabel);
        },

        async buy() {
            if (!this.tos) {
                this.$swal({
                    icon: "warning",
                    text: "Por favor acepta los términos y condiciones",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                });                
                return;
            }

            this.buyButton = false;
            this.buying = true;
            let hasProvider = await Web3Service.dApp.hasProvider();
            // Encapsulate this to reuse in connect()
            if (!hasProvider) {
                // redirect to mobile dapp browser
                // second line is for iPad since UA is same as desktop
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
                   (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) 
                ) {
                    // mobile device
                    this.$swal({
                        icon: "info",
                        title: "Metamask Móvil",
                        text: "Continua tu experiencia en la app de Metamask!",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    })
                    .then(() => {
                        let url = "https://metamask.app.link/dapp/avatares.io/";
                        url += this.avatarSlug + "/" + this.collectionSetSlug;
                        window.location = url;
                    });
                } else {
                    // it's desktop with no metamask installed
                    this.$swal({
                        icon: "error",
                        title: "Instalar Metamask",
                        html: "Seguí las <a href='/ayuda/compraresc' target='_blank'>instrucciones</a> para instalar Metamask",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true
                    });
                }
                this.buyButton = true;
                this.buying = false;
                return;
            }
            // check connection
            this.isConn = await Web3Service.dApp.isConnected();
            if (!this.isConn) {
                this.$swal({
                    icon: "info",
                    title: "Conectar Metamask",
                    html: "Seguí las <a href='/ayuda/compraresc' target='_blank'>instrucciones</a> para conectar tu Metamask",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    onOpen: async () => {
                        this.$swal.showLoading();
                    }
                });
                this.buyButton = true;
                this.buying = false;
                return;
            }
            // Check chain
            let chainId = await Web3Service.dApp.web3.eth.getChainId();
            if (chainId != Web3Service.dApp.rightChain) {
                this.$swal({
                    icon: "error",
                    title: "Cadena Polygon",
                    text: "Vamos a cambiar a la cadena Polygon",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                })
                .then(async() => {
                    await Web3Service.switchToPoly(this.updateBalance);
                });
                this.buyButton = true;
                this.buying = false;
                return;
            }
            console.log("chain id", chainId);
            // Check if wallet has enough BNB
            this.balance = await Web3Service.dApp.balance();
            if (this.balance < this.priceLabel) {
                this.$swal({
                    icon: "error",
                    title: "No alcanza :(",
                    html: "No te alcanzan los MATIC para comprar... Seguí las <a href='/ayuda/compraresc' target='_blank'>instrucciones</a> para comprar MATIC con tarjeta o gito bancario",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                });
                this.buyButton = true;
                this.buying = false;
                return;
            }
            this.purchaseDone = false;
            this.firebase = new FirebaseService();
            this.user = await this.firebase.getProfile("wallet", Web3Service.dApp.address);
            // Purchase
            this.chainBuy();
        },

        chainBuy() {
            this.loadExchangeContract();
            this.exchange.methods.buy(this.collectionSetId).send(
                {
                    from: Web3Service.dApp.address, gasPrice: 0, value: this.priceDec
                }
            )
            .on("error", (error) => {
                let message = error.message.substring(0, 128) + "...";
                this.$swal({
                    icon: "error",
                    title: "Error",
                    html: message + "<br>Please try again." 
                });
                this.buyButton = true;
                this.buying = false;
            })
            .on("transactionHash", (hash) => {                
                window.scrollTo(0, 0);
                this.txLink = SCANNER + "/tx/" + hash;
                this.buySection = false;
                this.inProgressSection = true;
            })
            .then((receipt) => {
                let allCollections = CollectionService.main.getAllCollections();
                let index = receipt.events.Sold.returnValues.collectionId;
                this.checkAvailable();
                // NFT preview
                this.purchasedCollectionId = index;
                this.previewTitle = allCollections[index].title; 
                this.previewUrl = allCollections[index].image_cache;
                this.previewDesc = allCollections[index].description;
                this.buySection = false;
                this.inProgressSection = false;
                this.purchaseDone = true;
                // Update balance in header
                this.emitter.emit("header:update:balance");

            });
        },

        loadExchangeContractRo() {
            this.exchangeRo = new Web3Service.dApp.roWeb3.eth.Contract(
                Web3Service.dApp.exchangeAbi, Web3Service.dApp.exchangeAddress
            );
            // https://www.coinclarified.com/p/3-ways-to-subscribe-to-events-with-web3-js/
            console.log("Subscribing to events");
            this.exchangeRo.events.Sold(
                [],
                (error, event) => {
                    console.log("Event received");
                    console.log(error);
                    console.log(event);
                    if (event.returnValues.newOwner == Web3Service.dApp.address) {
                        this.purchaseCollectionId = event.returnValues.collectionId;
                        console.log("Last pruchase collection ID", this.purchaseCollectionId);

                    }
                }
            );
        },

        loadExchangeContract() {
            this.exchange = new Web3Service.dApp.web3.eth.Contract(
                Web3Service.dApp.exchangeAbi, Web3Service.dApp.exchangeAddress
            );
        },

        async checkAvailable() {
            console.log("[collection] checking available and price");
            this.available = await this.exchangeRo.methods.available(this.collectionSetId).call();
            // TODO: 
            if (this.available <= 0) {
                alert("No hay mas!");
                //disable buy
                // te puede gustar ...
            }

        },

        async getPrice() {
            console.log("[collection] getting price");
            let price = await this.exchangeRo.methods.price(this.collectionSetId).call();
            this.priceLabel = price / 10 ** 18;
            this.priceDec = price;
        },

        updateBalance() {
            this.emitter.emit("header:update:balance");
        },

        goToProfile() {
            this.$router.push("/@" + this.user.username);
        },

        goToOS() {
            let url = OPENSEA + Web3Service.dApp.factoryAddress + "/" + this.purchaseCollectionId; 
            window.open(url);
        },

        goToBuy() {
            let url = "/" + this.avatarSlug + "/" + this.collectionSetSlug; 
            window.location = url;
            // this.$router.push(url);
        },

        goToVIP() {
            let url = "/vip"; // + this.avatarSlug + "/" + this.collectionSetSlug + "/vip"; 
            this.$router.push(url);
        },

        blockchain() {
            let html = `
                <table class="table local-table">
                <tbody style="font-size: 0.8rem">
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Cadena</th>
                        <td style="text-align: left;">
                            <a href="https://polygon.technology" target="_blank">Polygon</a>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Dirección</th>
                        <td style="text-align: left;">` + Web3Service.dApp.exchangeAddress + `</td>
                    </tr>
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Clase</th>
                        <td style="text-align: left;">` + this.collectionSetId + `</td>
                    </tr>
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Metadata</th>
                        <td style="text-align: left;">ipfs://:` + Web3Service.dApp.metadata + `</td>
                    </tr>
                    <tr>
                        <th scope="row" width="30%" style="text-align: left;">Ediciones</th>
                        <td style="text-align: left;">` + this.total + `</td>
                    </tr>


                </tbody>
                </table>
            `;
            this.$swal({
                // iconHtml: '<img src="https://picsum.photos/100/100">',
                // customClass: {
                //     icon: 'no-border'
                // },
                title: this.name,
                html: html,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
            });

        },

        timer() {
            var days = Math.floor(seconds/24/60/60);
            var hoursLeft = Math.floor((seconds) - (days*86400));
            var hours = Math.floor(hoursLeft/3600);
            var minutesLeft = Math.floor((hoursLeft) - (hours*3600));
            var minutes = Math.floor(minutesLeft/60);
            var remainingSeconds = seconds % 60;
            function pad(n) {
                return (n < 10 ? "0" + n : n);
            }
            // document.getElementById('countdown').innerHTML = pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds);
            this.countdown = days + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds);
            if (seconds <= 0) {
                clearInterval(this.countdownTimer);
                this.showCountdown = false;
                this.saleEnabled = 1;
                this.countdown = "";
            } else {
                seconds--;
            }
        }

    }
}
</script>

<style scoped>
.text {
  width: 50%;
  border: 1px solid #ccc;
  height: 20px;
  padding: 12px 20px;
  margin: 4px;
  border-radius: 4px;
}

.profile {
  display: list-item;
  text-align: center;
}

.button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.avail-local {
  padding-top: 1.4rem;
}

.local-progress {
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
}

.local-line {
  margin-top: 0.7rem;
  display: inline-block;
  width: 14rem;
}

.local-link {
  cursor: pointer;
  font-size: 1.1rem;
  margin-top: 1rem;
}

.local-link i {
  width: 1.3rem;
  color: #1E88E5;
}

.local-bigspinner {
  width: 4rem;
  height: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.local-available {
  color: #333;
}

.local-badge {
  color: #1E88E5;
  font-size: 1.3rem;
}

.local-tos {
  margin-top: 0.7rem;
}

.local-prebuy {
  width: 35%;
}

.local-loader {
  margin-top: 2rem;
}

.wrapper {
  padding-bottom: 3rem;
}

a {
  color: #1E88E5;
}

.local-countdown {
  color: #C62828;
}

.local-checkbox {
  width: 1.5rem;
  height: 1.5rem;
}

.local-form-check {
  position: relative;
  margin-bottom: 0;
  vertical-align: middle;
  line-height: 2.1rem;
  margin-left: 0.5rem;
}
</style>