import { createApp } from 'vue';
import App from './App.vue';
import mitt from 'mitt';
import router from './router'
import store from './store'
import * as bootstrap from 'bootstrap';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const emitter = mitt();

const app = createApp(App)
    .use(bootstrap)
    .use(store)
    .use(router)
    .use(VueSweetalert2);
app.config.globalProperties.emitter = emitter;
// from https://stackoverflow.com/questions/63471824/vue-js-3-event-bus
app.mount('#app');
app.component('iq-card', require('./components/iq-card').default);
