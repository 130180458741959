<template>

  <div class="container col-xxl-8 px-4 py-5 marketing">
    <!-- <div class="row flex-lg-row-reverse align-items-center g-5 ">
      <div class="col-10 col-sm-8 col-lg-6">
        <img src="@/assets/images/blog/01.jpg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
      </div>
      <div class="col-lg-6">
        <h1 class="display-5 lh-1 mb-3">El primer mercado de avatares NFT</h1>
        <p class="lead">
            Avatares es el primer mercado de avatares NFT en español. Los avatares NFT 
        </p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <button type="button" class="btn btn-primary btn-lg px-4 me-md-2">Iconos del Horror</button>
          <button type="button" class="btn btn-outline-secondary btn-lg px-4">Ayuda</button>
        </div>
      </div>
    </div> -->


    <div class="row featurette">
      <div class="col-md-7">
        <!-- <h3 class="featurette-heading"><span class="text-muted">El primer club de</span> Avatares NFT
        <span class="text-muted">en español</span>
        </h3> -->
        <h3 class="featurette-heading">El Primer Club de <b>Avatares NFT
        </b>en Español
        </h3>

        <p class="lead">
            Comprá tus avatares NFT que además de ser coleccionables, te darán acceso a contenido exclusivo,
            sorteos y otros eventos de tus personajes favoritos. La primera colección de Avatares "Herederos del 
            Horror" estará disponible muy pronto.
        </p>


        <div class="d-grid gap-2 d-md-flex justify-content-md-start local-main-button">
          <button type="button" class="btn btn-primary me-2" v-on:click="goToFeature">Herederos del Horror</button>
        </div>

      </div>
      <div class="col-md-5">

        <video width="385" autoplay muted loop>
            <source src="@/assets/images/horror_comp.mp4" type="video/mp4">
            <!-- <source src="mov_bbb.ogg" type="video/ogg"> -->
            Your browser does not support HTML5 video.
        </video>


        <!-- <img src="@/assets/images/avatares-horror-1.png" width="384" /> -->
      </div>
    </div>

    <hr style="color: transparent; margin: 5rem 0">

    <h2 class="pb-2 ">Avatares NFT con utilidades</h2>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div class="col d-flex align-items-start">
        <div class="icon-square text-dark flex-shrink-0 me-3">
          <i class="far fa-file-image local-item"></i>
        </div>
        <div>
          <a href="/ayuda/nft#nfts0">
          <h4>Qué es un NFT?</h4>
          </a>
          <p>
              Informate acerca de lo que son los NFTs y como el blockchain ha permitido la creacion de los mismos junto con las criptomonedas
              (<a href="/ayuda/nft#nfts0">más</a>)
          </p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <div class="icon-square text-dark flex-shrink-0 me-3">
          <i class="far fa-user local-item"></i>
        </div>
        <div>
          <a href="/ayuda/nft#nfts1">
          <h4>Qué es un Avatar NFT?</h4>
          </a>
          <p>
              Los avatares NFT son NFTs cuya imagen esta diseñada para ser utilizada como foto de perfil. Generalmente es la ilustración de un personaje 
              (<a href="/ayuda/nft#nfts1">más</a>)
              
          </p>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <div class="icon-square text-dark flex-shrink-0 me-3">
            <i class="far fa-star local-item"></i>
        </div>
        <div>
          <a href="/ayuda/nft#nfts2">
          <h4>Qué es Avatares?</h4>
          </a>
          <p>
            Avatares.io es un club de avatares NFT que produce colecciones temáticas asi como también inspiradas en figuras públicas
            (<a href="/ayuda/nft#nfts2">más</a>)
          </p>
        </div>
      </div>
    </div>

    <hr style="color: transparent; margin-bottom: 5rem">

    <div class="row featurette">
      <div class="col-md-7 order-md-2">
        <h3 class="featurette-heading">Herederos del Horror: <span class="text-muted">Exclusivo de Avatares</span></h3>
        <p class="lead">
            Esta primerísima colección piloto de Avatares retrata a los descendientes directos de nuestros personajes
            favoritos del horror: los tátara,tátara,(10 u 11 veces más...) nietos y nietas del engendro, el vampiro, el zombie y 
            la momia, en su adolescencia. 
        </p>
    
        <div class="d-grid gap-2 d-md-flex justify-content-md-start local-main-button">
          <button type="button" class="btn btn-primary me-2" v-on:click="goToFeature">Herederos del Horror</button>
        </div>


      </div>
      <div class="col-md-5 order-md-1">
        <img src="@/assets/images/avatares-horror-2.png" width="360" />
      </div>
    </div>

    <hr style="margin-top: 5rem">

    <div class="row py-lg-5">
      <div class="col-lg-8 col-md-10 mx-auto">
        <h1 class="fw-light">Ya llegan ...</h1>
        <p class="lead text-muted">
            ... futuras colecciones de avatares NFT que incluyen artistas, bandas musicales y figuras de 
            habla hispana. <b>Artistas, bandas y figuras:</b> si desean proveer a sus seguidores con coleccionables digitales
            que pueden ofrecer una experiencia única a traves de una comunidad de avatares NFT,
            por favor comunicarse con nosotros. 
        </p>
        <p>
          <a href="mailto:contacto@avatares.io" target="_blank" class="btn btn-success my-2">Contactenos</a>
        </p>
      </div>
    </div>


  </div>

  <!-- <div class="container marketing">
    <div class="row featurette">
      <div class="col-md-7">
        <h2 class="featurette-heading">First featurette heading. <span class="text-muted">It’ll blow your mind.</span></h2>
        <p class="lead">Some great placeholder content for the first featurette here. Imagine some exciting prose here.</p>
      </div>
      <div class="col-md-5">
        <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#eee"/><text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text></svg>

      </div>
    </div>

  </div> -->





</template>

<script>

export default {
    name: 'Home',
    data() {
        return {}
    },
    mounted() {
        console.log("[home] mounting");
    },

    methods: {
        goToFeature() {
            window.location = "/avatares/horror";
        }
    }
}
</script>

<style scoped>
.wrapper {
  padding-bottom: 3rem;
}

.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* rtl:end:ignore */

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 1rem 0; /* Space out the Bootstrap <hr> more */
}

.featurette-divider-pos {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}


/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1.4;
  /* rtl:remove */
  letter-spacing: -.05rem;
  font-size: 1.8rem;

}

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 4rem;
  }
}

.local-main-button {
  margin-bottom: 1rem;
}

.local-item {
  font-size: 2rem;
  color: #666;
}

</style>
