<template>

  <div class="container" style="padding: 1rem; margin-bottom: 3rem;">

    <div class="row py-lg-5">

      <div class="col-lg-10 col-md-12 mx-auto">

        <h4 class="fw-light">
            <a href="/ayuda">Ayuda</a> > Comprar <span class="text-muted">(móvil)</span>
        </h4>


        <h1 class="fw-light">
            Como comprar desde tu móvil
        </h1>

        <h3 class="fw-light">Sumario</h3>
        <ol>
            <li>Crea tu billetera</li>
            <li>Compra MATIC</li>
            <li>Accede al sitio</li>
            <li>Conecta la billetera</li>
            <li>Compra el NFT</li>    
        </ol>

        <h3 class="fw-light">1. Crea tu billetera</h3>
        <h6 class="text-muted">Si ya tenés una billetera de criptomonedas en tu móvil, podés saltear al paso 2.</h6>

        <p class="lead">
            Para poder comprar avatares NFTs en Avatares, primero hay que tener una billetera de criptomonedas
            en dónde almacenar la criptomoneda (MATIC) para poder pagar el NFT, y también para almacenar
            el NFT.<br>
            Desde el móvil recomendamos user Metamask como billetera.
        </p>

        <p class="lead">
            Metamask es una de las billeteras más famosas y utilizadas por el público cripto.<br>
            En iPhone se puede instalar desde el 
            <a href="https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202">
            AppStore</a>, y para Android desde el 
            <a href="https://play.google.com/store/apps/details?id=io.metamask&hl=es_ES">
            PlayStore</a>.
            <br>
            <b>MUY IMPORTANTE!</b>: la aplicación te va a dar en algún momento la frase secreta (12 palabras en inglés). <b>Anota
                la frase secreta en un papel (no en el celular o en la computadora), guardalo en un lugar seguro, y lo más importante,
                NO SE LA DEBES DAR A NADIE</b>! Ni a un soporte de Metamask, Avatares, o cualquier sitio,
                a nadie!! Ya que si das la frase secreta tendrán acceso a tu billetera y podrán sacarte
                todas las criptomonedas y los NFTs. Reiteramos, a nadie!
            <br>También se pueden seguir estas instrucciones:<br><br>
            <iframe width="80%" height="320" src="https://www.youtube.com/embed/EOCxbT0CnPQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <br><br>
        </p>

        <h3 class="fw-light">2. Compra MATIC</h3>
        <p class="lead">
            Los avatares NFT de Avatares se pueden pagar con la moneda de Polygon (MATIC).
            Dependiendo del país donde estés se puede comprar MATIC con tarjeta de crédito o con transferencia
            bancaria.
            <ul>
                <li><b>Con TARJETA DE CRÉDITO:</b> se puede comprar directamente en
                    <a href="https://transak.com" target="_blank">Transak</a>.<br>
                    También se puede comprar en
                    <a href="https://www.binance.com/es/buy-sell-crypto" target="_blank">Binance</a> pero
                    hay que comprar USDT primero, y luego con USDT se compra MATIC y por último se transfiere
                    el MATIC a tu billetera Metamask. Se puede seguir este tutorial paso a paso:<br><br>
                    <iframe width="80%" height="320"  src="https://www.youtube.com/embed/rq08SPlwvoM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <br><br>
                </li>
                <li><b>Con TRANSFERENCIA BANCARIA:</b>
                    Primero se debe depositar desde tu banco a Binance, luego comprar USDT, con USDT comprar MATIC y por último
                    se transfiere el MATIC a tu billetera Metamask. Para depositar de tu banco a Binance, se pueden seguir estas
                    instrucciones, no olvides de extraer el MATIC a la billetera Metamask que creaste en el paso anterior:<br><br>
                    <iframe width="80%" height="320" src="https://www.youtube.com/embed/oRWOS7NJBH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <br><br> 
                </li>
            </ul>

        </p>

        <h3 class="fw-light">3. Accede al sitio</h3>
        <p class="lead">
            Para poder comprar desde el móvil se debe acceder a Avatares.io desde el navegador de
            Metamask:
        <ul>
            <li>Abrir la aplicación Metamask (te va a pedir autenticación)
            </li>
            <li>Tocar en el ícono "hamburguesa" (las 3 líneas horizontales) arriba a la izquierda
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metamask2.png" />
            <li>Tocar "Explorador"
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metamask3.png" />
            <li>Tocar en la barra de URL
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metamask4.png" />
            <li>Tipear avatares.io y la tecla de entrada o "Enter"
            </li>
        </ul>
        </p>

        <h3 class="fw-light">4. Conecta tu billetera</h3>
        <p class="lead">
            Ahora hay que conectar la billetera antes de poder comprar. Desde la página en el navegador de Metamask:
        </p>
        <ul>
            <li>Tocar en el ícono "hamburguesa" (las 3 líneas horizontales) arriba a la derecha
            </li>
            <li>Tocar "Conectar"
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/connect1.png" />
            <li>Seguir las instrucciones del navegador, básicamente va a preguntar confirmación para
                conectarse a Avatares.io
            </li>
            <li>Si es la primera vez que ingresas al sitio, te va a preguntar por nombre de usuario,
                email, tu cuenta de Twitter y tu cuenta de Instagram
            </li>
            <li>El nombre de usuario y email son obligatorios - los demás campos son opcionales
            </li>
        </ul>

        <h3 class="fw-light">5. Compra el NFT</h3>
        <p class="lead">
            Una vez conectada tu billetera, la cual ya tiene MATIC, ya estás todo listo para comprar!
        </p>
        <ul>
            <li>Ir a la colección que se desea comprar
            </li>
            <li>Leer y chequear los "términos y condiciones"
            </li>
            <li>Tocar "Comprar Avatar"
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuymob1.png" />
            <li>La billetera preguntará por autorización para transferir el precio del NFT en MATIC (más tarifas de gas).
                Tocar "Confirmar"
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuymob2.png" />
            <li>Se generará un avatar NFT de la colección al azar y será
                transferido a tu billetera:
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuymob3.png" />
            <li>Y luego se revelará qué te tocó:
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuymob4.png" />

            <li>Cabe destacar que estarás comprando una sola unidad del avatar NFT, y que el NFT 
                será generado al azar dentro de las opciones que la colección ofrece
            </li>
            <li>El avatar NFT generado no se puede cambiar: el que toca, toca; y no se puede predecir
                cuál va a ser generado
            </li>
            <li>El NFT no tendrá token ID, pertenecerá a una de las clases de la colección
            </li>    
        </ul>


        <p>Para más preguntas por favor 
            <a href="mailto:contacto@avatares.io" target="_blank">contactarse con nosotros</a>.
        </p>
        

      </div>
    </div>

  </div>


</template>

<script>
export default {
    name: 'HelpBuyMob',
    data() {
        return {}
    },
    mounted() {
        console.log("[home] mounting");
    },

    methods: {
    }
}
</script>

<style scoped>

</style>
