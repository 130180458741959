import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";

const getDefaultState = () => {
    return {
        profile: {
            email: null,
            wallet: null,
            avatar: null,
            username: null,
            docsPath: null
        }
    }
}

export default createStore({
    state: {
        profile: {
            email: null,
            wallet: null,
            avatar: null,
            username: null,
            docsPath: null
        }
    },
    mutations: {
        mutationProfile: (state, data) => {
            state.profile = data;
        },
        resetState (state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {
        getProfile: state => {
            return state.profile;
        },
        resetState (context) {
            context.commit('resetState')
        }
    },
    modules: {
        changeProfile: ({commit}, value) => {
            commit('mutationProfile', value);
        },
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => Cookies.get(key),
                setItem: (key, value) =>
                    Cookies.set(key, value, {expires: 3, secure: true}),
                removeItem: (key) => Cookies.remove(key),
            },
        }),
    ]
})