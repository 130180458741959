<template>

<div>Deslogueado!</div>

</template>

<script>
/** 
 *  https://firebase.google.com/docs/firestore/query-data/query-cursors
 *  
 * 
 */



//import CollectionService from "@/services/CollectionService";
import FirebaseService from "@/services/FirebaseService";
//import Web3Service from "@/services/Web3Service";

export default {
    name: "Logout",
    data() {
        return {
        }
    },
    async mounted() {
        console.log("[logout]");
        this.firebase = new FirebaseService();
        this.firebase.signout(this.loggedOut);
    },

    methods: {
        loggedOut() {
            console.log("Logged out!");
        }
    }
}
</script>

<style scoped>

</style>