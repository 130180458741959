<template>

  <div class="container" style="padding: 1rem">

    <div class="row py-lg-5">

      <div class="col-lg-10 col-md-12 mx-auto">
        <h1 class="fw-light">Main title</h1>

        <h2 class="fw-light">sdfdsfsdf</h2>
        <p class="lead text-muted">
            ... futuras colecciones de avatares NFT que incluyen comicos, bandas musicales y artistas de 
            fama internacional. <b>Artistas, bandas y personajes:</b> si desean proveer a sus seguidores con coleccionables digitales
            que pueden crear una experiencia augmentada a traves del primer mercado
            de avatares NFT para el publico de habla hispana, por favor comunicarse con nosotros. 
        </p>

      </div>
    </div>

  </div>

</template>

<script>
export default {
    name: 'About',
    data() {
        return {}
    },
    mounted() {
        console.log("[home] mounting");
    },

    methods: {
    }
}
</script>

<style scoped>

</style>
