<template>

  <div class="container" style="padding: 1rem">

    <div class="row py-lg-5">

      <div class="col-lg-10 col-md-12 mx-auto">
        <h1 class="fw-light">Política de Privacidad</h1>

        <p class="lead text-muted">
Esta es la Política de Privacidad y Cookies del sitio web de Avatares ("nosotros", y "nuestro sitio") (conjuntamente, la "Plataforma").
La Plataforma es operada por o en nombre de Avatares Ltd. Estamos comprometidos a proteger y respetar su privacidad.
Por favor lea atentamente lo siguiente para comprender nuestras opiniones y prácticas con respecto a sus datos personales y cómo los trataremos. Al utilizar la Plataforma, usted reconoce que ha leído y entendido esta política de privacidad. A los efectos de la ley de protección de datos del Espacio Económico Europeo (la "Ley de Protección de Datos"), el controlador de datos es: Avatares Ltd.
        </p>

        <h3 class="fw-light">La información que recopilamos de Usted</h3>
        <p class="lead text-muted">
Recopilaremos y procesaremos los siguientes datos personales de Usted:
<ul>
<li>La información que usted nos proporciona. Se trata de la información sobre usted que nos proporciona directamente cuando interactúa con nosotros y que incluye su nombre de usuario, su correo electrónico y cuenta de Twitter o Instagram, si decide proporcionarnos esta información.</li>
<li>Información que recopilamos sobre usted a partir del uso que realiza de la Plataforma: Recopilaremos automáticamente su información cada vez que visite nuestro sitio.</li>
<li>Esto incluye: Información técnica, que puede incluir la dirección del protocolo de Internet (IP) utilizada para conectar su computadora a Internet, su información de acceso, el tipo y la versión del navegador, la configuración de la zona horaria, los tipos y versiones de los complementos del navegador, el sistema operativo y la plataforma.</li>
<li>Información sobre su visita, el cual puede incluir los localizadores uniformes de recursos (URL) completos, el flujo de clics hacia, a través y desde nuestro sitio (incluida la fecha y la hora), los productos que vio o buscó, los tiempos de respuesta de la página, los errores de descarga, la duración de visitas a ciertas páginas, información de interacción de la página (como desplazamiento, clics y movimientos del mouse), métodos utilizados para navegar fuera de la página.</li>
</ul>
        </p>

        <h3 class="fw-light">Cookies y otras tecnologías</h3>
        <p class="lead text-muted">
La Plataforma utiliza cookies y tecnologías similares para distinguirlo de otros usuarios de la Plataforma. La mayoría de los navegadores aceptan automáticamente las cookies y tecnologías similares, pero si lo prefiere, puede cambiar su navegador para evitarlo y su pantalla de ayuda o manual le indicará cómo hacerlo. También le damos información sobre cómo deshabilitar las cookies en la tabla. Sin embargo, es posible que no pueda aprovechar al máximo nuestro sitio web si lo hace.
Una serie de cookies y tecnologías similares que utilizamos duran sólo por la duración de su sesión web o de la aplicación y expiran cuando usted cierra su navegador. Otros sirven para recordarle cuando vuelva a la Plataforma y durarán más tiempo.
Utilizamos estas cookies y otras tecnologías con base a que son necesarias para la ejecución de un contrato con usted, o porque su uso responde a nuestros intereses legítimos (cuando hemos considerado que éstos no son anulados por sus derechos) y, en algunos casos, cuando lo exige la ley, cuando usted ha consentido su uso.
Utilizamos los siguientes tipos de cookies: Cookies estrictamente necesarias. Se refieren a cookies que se requieren para el funcionamiento de nuestro sitio web y según nuestros términos con usted. Incluyen, por ejemplo, cookies que le permiten iniciar sesión en áreas seguras de nuestro sitio web.
Cookies analíticas/de rendimiento. Nos permiten reconocer y contar el número de visitantes y ver cómo se desplazan los visitantes por nuestro sitio web cuando lo están utilizando. Esto nos ayuda a nuestros intereses legítimos de mejorar la manera en que funciona nuestro sitio web, por ejemplo, al garantizar que los usuarios encuentren lo que buscan fácilmente.
        </p>


        <h3 class="fw-light">Información de contacto</h3>
        <p class="lead text-muted">
Por favor envíe cualquier pregunta que tenga sobre esta política de privacidad o cualquier problema relacionado con la Plataforma por correo electrónico a 
<a href="mailto:contacto@avatares.io">contacto@avatares.io</a>
        </p>


      </div>
    </div>

  </div>

</template>

<script>
export default {
    name: 'Privacy',
    data() {
        return {}
    },
    mounted() {
        console.log("[home] mounting");
    },

    methods: {
    }
}
</script>

<style scoped>

</style>
