<template>

  <div class="container" style="padding: 1rem; margin-bottom: 3rem;">

    <div class="row py-lg-5">

      <div class="col-lg-10 col-md-12 mx-auto">

        <h4 class="fw-light">
            <a href="/ayuda">Ayuda</a> > Acceso VIP (Móvil)
        </h4>

        <h1 class="fw-light">
            Acceso VIP desde tu Móvil
        </h1>

        <h3 class="fw-light"></h3>
        <p class="lead">
            Felicitaciones! Una vez comprado el avatar NFT, no sólo se puede empezar a utilizar como 
            foto de perfil en Avatares y plataformas sociales, también se puede acceder al VIP donde se podrá apreciar
            contenido exclusivo y participar de sorteos y otros eventos.<br>
        </p>

        <ul>
            <li>Tocar en el ícono "hamburguesa" (las 3 líneas horizontales) arriba a la derecha y luego en tu avatar:</li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/vipmob1.png" />

            <li>A la derecha podrás ver los avatares NFT que poseas. En cada avatar NFT adquirido hay un link "Usar como avatar" - tocarlo para utilizar ese NFT como foto de perfil
                en Avatares.io
            </li>
            <li>Para utilizar el NFT como foto de perfil en otros sitios (por ejemplo Twitter, Instagram, etc.)
                tocar la imagen del NFT para descargar la imagen, y luego seguir las instrucciones de la plataforma
            </li>
            <li>También en cada avatar habrá un link "Ver en Opensea" - 
                tocar ahi para ver el NFT en Opensea, donde podés revenderlo o regalárselo a alguien (una vez que
                vendas el NFT o se lo regales a alguien, no tendrás más acceso al contenido exclusivo de ese avatar).
            </li>

            <li>La tarjeta principal (a la izquierda) tendrá tu información (foto de perfil, nombre de usuario, billetera)
                tocar abajo en el botón "Acceso VIP":
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/vipmob2.png" />

            <li>Así entrarás al club VIP con contenido exclusivo:
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/vipmob3.png" />
        </ul>

        <h3 class="fw-light">Navegando el VIP</h3>
        <ul>
            <li>El VIP es como un muro social con mensajes, fotos y/o videos exclusivos de los avatares NFT 
                adquiridos
            </li>
            <li>Ciertas colecciones pueden incluir sorteos y otros eventos
            </li>
            <li>El contenido exclusivo de cada colección es posteado dos o más veces por mes
            </li>
            <li>Cada colección posteará mensajes, fotos y/o videos hasta 12 meses a partir del lanzamiento de 
                la colección, dependiendo de la colección
            </li>
            <li>El posteo de contenido de cada colección no está garantizado por Avatares.io. Si por ejemplo
                el cantante ABC lanza una colección de avatares NFT, hará lo posible para postear contenido
                exclusivo al menos dos veces por mes durante 12 meses. Pero si el cantante tiene inconvenientes 
                (por ejemplo problemas de salud, fallecimiento, legalidades, etc.) para proveer contenido,
                Avatares no será responsable de dicho cese.  
            </li>
        </ul>


      </div>
    </div>

  </div>

</template>

<script>
export default {
    name: "HelpClubMob",
    data() {
        return {}
    },
    mounted() {
        console.log("[home] mounting");
    },

    methods: {
    }
}
</script>

<style scoped>

</style>
