<template>

  <div class="container" style="padding: 1rem; margin-bottom: 3rem;">

    <div class="row py-lg-5">

      <div class="col-lg-10 col-md-12 mx-auto">

        <h4 class="fw-light">
            <a href="/ayuda">Ayuda</a> > NFTs
        </h4>


        <h1 class="fw-light">
            Acerca de los NFTs
        </h1>

        <h2 class="fw-light" id="nfts0">El Blockchain</h2>
        <p class="lead">
            Para entender qué son los NFTs (o Non Fungible Tokens, por sus siglas en inglés) primero debemos entender
            de qué se trata el blockchain: El blockchain o cadena de bloques es como un libro mayor, 
            pero digital y que vive en el Internet. Lo que se escribe en el blockchain queda asentado, certificado
            y se garantiza su integridad, disponibilidad y propiedad. Este registro único e inalterable está distribuido en
            decenas, centenas o hasta miles de servidores en el Internet (dependiendo de la cadena) y no existe entidad,
            grupo o persona que pueda cambiar o borrar estos registros (deberían tener acceso y control de los miles
            de servidores mencionados), por eso se dice que el blockchain esta "descentralizado".<br>
            Uno de los mayores usos del blockchain es llevar la contabilidad de "activos digitales" (también llamados
            "tokens") por ejemplo Bitcoin y Ethereum.
        </p>

        <h2 class="fw-light" id="nfts1">Qué es un NFT?</h2>
        <p class="lead">
            Asi como Bitcoin y Ethereum son tokens los NFTs también son tokens, pero no fungibles que pueden
            ser agrupados en "colecciones".
            Un bitcoin se puede intercambiar por otro bitcoin y no habría ninguna diferencia, y un grupo
            de 5 bitcoins serían iguales a otro grupo o colección de otros 5 bitcoins. Un NFT
            puede ser único, irreproducible y ser creado en distintas colecciones; que además cuenta con:
            <ul>
                <li>Nombre y descripción: identificando al token</li>
                <li>Medio digital: una imagen o video</li>
            </ul>
            Una analogía muy utilizada 
            para describir a los NFTs es la de las copias de arte limitadas: los representantes de Monet han impreso
            exactamente 50 copias de "Mujer con sombrilla" y solo 50 personas la pueden tener. El original de
            "Mujer con sombrilla" sería un NFT, y las restantes 49 copias serían otros NFTs.<br>
            El uso principal de los NFTs hoy en dia es el de coleccionable digital: arte, música, fotos, animaciones, ilustraciones.
            Dentro de la categoría de las ilustraciones, hay un tipo que se utiliza como foto de perfil para identificarse en
            el mundo digital: el "Avatar NFT".  
        </p>

        <h2 class="fw-light" id="nfts2">Qué es un Avatar NFT?</h2>
        <p class="lead">
            El avatar NFT es un NFT cuya imagen esta diseñada para ser utilizada como foto de perfil. 
            Generalmente es la ilustración de un personaje (de los hombros para arriba) con una base en común
            más atributos que pueden variar: color de pelo, estilo de peinado, sombrero, anteojos, barba, bigote,
            aros, mascaras, color de piel, etc.<br>
            Los avatares NFT sirven para identificarse en el internet: ponerlos como foto de perfil
            en los distintos medios sociales, plataformas de juegos o incluso en firmas digitales. 
            Los avatares NFT se utilizan como un medio para comunicar pertenencia, afinidad y por qué no,
            símbolo de status o insignia de honor. De la misma manera que uno compra una chaqueta de marca reconocida
            que luce su logo identificatorio, en el mundo digital esta clase de expresión se hace a través del avatar
            en primera instancia.<br>
            <b>Qué pasa si alguien se baja la imagen de Internet y la pone en su perfil?</b>
            Sí, esto va a suceder y no hay manera de evitarlo. Pero es la misma situación en el ejemplo de la
            chaqueta de marca reconocida: habrá gente que va a conseguir réplicas o imitaciones de la chaqueta
            a una fracción del costo del original, pero tarde o temprano la gente se da cuenta de que no es el original.
        </p>

        <h2 class="fw-light" id="nfts3">Qué es Avatares?</h2>
        <p class="lead">
            Avatares.io es un club de avatares NFT que produce colecciones temáticas asi como también 
            inspiradas en artistas, cómicos, músicos y otras figuras públicas.
            Pero además de servir como foto de perfil y ser un coleccionable digital, las colecciones emitidas por 
            Avatares vienen con utilidad: son "tokens de acceso". En Avatares los propietarios de NFTs
            tienen acceso a una comunidad con contenido exclusivo y pueden participar de sorteos y otros eventos.
            Nuestros avatares NFT están emitidos en la cadena 
            <a href="https://polygon.technology" target="_blank">Polygon</a>, 
            la cual es 100% compatible con la cadena  
            <a href="https://ethereum.org" target="_blank">Ethereum</a> pero consume 99% menos de energía y las
            transacciones se ejecutan 5000 veces mas rápido.
            Aparte del impacto al medio ambiente, la cadena Polygon puede ser 300+ veces más 
            económica comparada a las tarifas de gas de Ethereum. Una compra de un NFT en la cadena Polygon cuesta menos
            de U$D 0.05, comparado con Ethereum que puede llegar a costar U$D 150 o más según
            la tarifa de gas y el precio del Ether.<br>
            Nuestras colecciones están creadas bajo el estandar 
            <a href="https://eips.ethereum.org/EIPS/eip-1155" target="_blank">Ethereum ERC-1155</a> y los tokens
            son semi-fungibles: los tokens no tienen token ID (una especie de número de serie) pero pertenecen a una clase o colección,
            por cada colección hay distintos artes, y al comprar el usuario obtendrá un arte al azar. 
            Y en un futuro a mediano plazo, la utilidad de las colecciones de Avatares se extenderá al metaverso:
            podrán usarse como avatares, cuadros, esculturas, objetos o hasta habilidades.
        </p>
    
        <p>Para más preguntas por favor 
            <a href="mailto:contacto@avatares.io" target="_blank">contactarse con nosotros</a>.
        </p>
        

      </div>
    </div>

  </div>

</template>

<script>
export default {
    name: 'HelpNFT',
    data() {
        return {}
    },
    mounted() {
        console.log("[home] mounting");
    },

    methods: {
    }
}
</script>

<style scoped>

</style>
