<template>

<!-- loader -->

<div class="wrapper">
    <div id="content-page" class="content-page">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                <div class="iq-edit-list-data">
                    <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">Editar Perfil</h4>
                    </template>

                    <template v-slot:body>
                        <div class="row align-items-center">

                            <div class="form-group col-sm-6">
                                <label for="cno"  class="form-label">Usuario:</label>
                                <input type="text" class="form-control" name="username" id="username"
                                placeholder="NombreDeUsuario" v-model="form.username" @keydown.space.prevent>
                            </div>
                        

                            <div class="form-group col-sm-6">
                                <label for="email"  class="form-label">Email:</label>
                                <input type="email" class="form-control" name="email" id="email"
                                    placeholder="yo@avatares.io" :disabled="newUser == false"
                                    v-model="form.email" @keydown.space.prevent>
                            </div>

                            <div class="form-group col-sm-6">
                                <label for="url"  class="form-label">Twitter:</label>
                                <input type="text" class="form-control" name="twitter" id="twitter"
                                placeholder="YoTwitter" v-model="form.twitter" @keydown.space.prevent>
                            </div>

                            <div class="form-group col-sm-6">
                                <label for="url"  class="form-label">Instagram:</label>
                                <input type="text" class="form-control" name="instagram" id="instagram"
                                placeholder="YoInsta" v-model="form.instagram" @keydown.space.prevent>
                            </div>
                        </div>

                        <div class="spinner-border text-primary" role="status" v-show="loader">
                            <span class="visually-hidden">cargando ...</span>
                        </div>

                        <button v-show="saveBt" class="btn btn-primary me-2" v-on:click="preSave">Guardar</button>
                    </template>

                    </iq-card>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import FirebaseService from "@/services/FirebaseService";
import Web3Service from "@/services/Web3Service";
const Verifier = require("email-verifier");

export default {
    name: "ProfileEdit",
    data() {
        return {
            user: null,
            form: {
                username: "",
                email: "",
                twitter: "",
                instagram: ""
            },
            firebase: null,
            wallet: null,
            profile: null,
            mode: null,
            source: null,
            loader: true,
            saveBt: false,
            newUser: false
        }
    },


    async mounted() {
        this.firebase = new FirebaseService();
        // Check if connected
        let isCon = await Web3Service.dApp.isConnected();
        if (!isCon) {
            alert("not connected!!");
            this.$router.push("/");
        }

        // TODO: Check if connected wallet exists / is from current user

        this.wallet = Web3Service.dApp.address;

        // get logged user and their info
        this.firebase.getLoggedUser(this.loggedUser, this.notLoggedUser);

        // this.user = await this.firebase.getUser(this.wallet);

        // this.form.username = this.user.username;
        // this.form.twitter = this.user.twitter;
        // this.form.instagram = this.user.instagram;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.mode = urlParams.get("mode");
        this.source = urlParams.get("source");
    },

    methods: {

        async loggedUser(user) {
            this.user = user;
            // retrieve profile
            this.profile = await this.firebase.getProfile("wallet", this.wallet);
            if (!this.profile) {
                alert("WTF!");
                return
            }
            // populate
            this.form.username = this.profile.username;
            this.form.email = this.user.email;
            this.form.twitter = this.profile.twitter;
            this.form.instagram = this.profile.instagram;
            this.loader = false;
            this.saveBt = true;
        },

        notLoggedUser() {
            console.log("[profileEdit] brand new user!");
            this.loader = false;
            this.saveBt = true;
            this.newUser = true;
        },

        async preSave() {
            // Validate
            if (!this.form.username) {
                alert("Ingresa un nombre de usuario!");
                return;
            }
            var letterNumber = /^[0-9a-zA-Z]+$/;
            if (!this.form.username.match(letterNumber)) {
                alert("Solo letras y/o numeros en nombre de usuario!");
                return;
            }
            console.log("calling username exists with", this.form.username, this.wallet);
            let exists = await this.firebase.usernameExists(this.form.username, this.wallet);
            if (exists) {
                alert(this.form.username + " ya existe! Por favor elige otro nombre de usuario");
                return;
            }
            // email - ONLY check if it changed
            let curEmail = "";
            if (this.user && this.user.email) {
                curEmail = this.user.email;
            }
            if (this.form.email != curEmail) {
                var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!emailPattern.test(this.form.email)) {
                    alert("Por favor ingresa un email válido");
                    return;
                }
                // check if it exists


                this.$swal.fire({
                    //icon: "info",
                    title: "",
                    text: "Verificando email ...",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        this.$swal.showLoading();
                    }
                });
                let verifier = new Verifier("at_DQjvSoEXghweUeuYsX30AJgUMxz3r");
                verifier.verify(this.form.email, async (err, data) => {
                    if (err) {
                        alert("Por favor ingresa un email válido");
                        this.$swal.close();
                        return;
                    }
                    // if (data.disposableCheck == "false") {
                    //     alert("Parece un email descartable, por favor intenta con otro email");
                    //     return;
                    // }
                    if (data.dnsCheck == "false") {
                        alert("Dominio inexistente, por favor intenta con otro email");
                        this.$swal.close();
                        return;
                    }
                    if (data.formatCheck == "false") {
                        alert("Email incorrecto, por favor corregir");
                        this.$swal.close();
                        return;
                    }
                    // all good
                    await this.save();
                });
            } else {
                // all good
                this.save();
            }
        },


        async save() {
            this.$swal.fire({
                //icon: "info",
                title: "",
                text: "Guardando ...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            });

            if (!this.profile) {
                // new user, create profile first
                let error = await this.firebase.createProfile(
                    this.wallet, this.form.email, this.form.username,
                    this.form.twitter, this.form.instagram
                );
                if (error) {
                    // swal!!





                    alert(error);
                    this.$swal.close();
                    return;
                }
                // try to store user location
                try {
                    this.firebase.getIPInfo(this.firebase.updateUserLoc);
                } catch(err) {
                    console.log("[header] error getting location", err);
                }
            } else {
                let error = await this.firebase.updateUserProfile(
                    this.wallet,
                    this.form.username,
                    this.form.email,
                    this.form.twitter,
                    this.form.instagram
                );
                if (error) {
                    // swal



                    alert(error);
                }
                // if (this.mode == "signup") {
                //     let url = "/profile/avatar?mode=orig&source=" + this.source;
                //     this.$router.push(url);
                // }
            }
            this.$swal.close();
            if (this.source) {
                console.log(this.source);

                // try with
                window.location = this.source;


//                this.$router.push(this.source);
            } else {
                this.$router.push("/");
            }

        },

    }
}
</script>

<style scoped>

.profile-img-edit{
  position: relative;
    display: inline-block;
    height: 150px;
    width: 150px;
}
.profile-pic{
  max-width: 100%;
    display: block;
}
.p-image{
  position: absolute;
    top: auto;
    right: 8px;
    bottom: 10px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background: #50b5ff;
    color: #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}
.file-upload{
  display: none;
}
.upload-button{
  font-size: 1.5em;
}

</style>