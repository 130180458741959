<template>

<div v-show="loading">
    <div class="d-flex justify-content-center local-loader">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>

<div class="wrapper" v-show="loaded">
    <div id="content-page" class="content-page">
        <div class="container">

            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h3>{{ title }}</h3>
                            <div>
                                {{ description }}
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="row">

                <div class="col-lg-6" v-for="c in collectionSets" v-bind:key="c.collectionSetId">
                    <div class="card">
                        <div class="top-bg-image">
                            <img v-bind:src="c.image" class="img-fluid" alt="Responsive image">
                        </div>
                        <div class="card-body">
                            <a v-bind:href="c.avatar + '/' + c.slug" class="local-link">
                                <h4 class="">
                                    {{ c.title }}
                                    <i class="fas fa-check-circle local-badge"></i>
                                </h4>
                            </a>
                        <div>{{ c.description }}</div>
                    </div>
                    </div>
                </div>


            </div>


</div>
</div>
</div>


<!-- <div>
caca
    <h3>{{ title }}</h3>
    <p>{{ description }}</p>

    <div v-for="c in collectionSets" v-bind:key="c.collectionSetId">
        {{ c.title }}
        {{ c.description }}
        <a v-bind:href="c.avatar + '/' + c.slug">Coleccion</a>
    </div>


</div> -->

</template>

<script>
import CollectionService from "@/services/CollectionService";


export default {
    name: "AvatarHome",
    data() {
        return {
            collectionSetSlug: null,
            firebase: null,
            wallet: null,
            title: "",
            description: "",
            collectionSets: [],
            loading: true,
            loaded: false
        }
    },


    async mounted() {
        // this.firebase = new FirebaseService();
        // await Web3Service.dApp.isConnected();
        // this.wallet = Web3Service.dApp.address;
        // this.user = await this.firebase.getUser(this.wallet);

        this.collectionSetSlug = this.$route.params.avatar;
        console.log("nombre", this.collectionSetSlug);
        if (!this.collectionSetSlug) {
            alert("redirect to 404!!!");
            return;
        }
        let avatarInfo = CollectionService.main.getAvatar(this.collectionSetSlug);
        // not sure why rendering {{ avatarInfo.title }} starts giving an error
        this.title = avatarInfo.title;
        this.description = avatarInfo.description;
        this.collectionSets = avatarInfo.collectionSets;

        // in DB add name, contract address, abi, etc. etc.
        // display
        // buy now button -> validate if web3 etc.

        window.addEventListener("load", () => {
            this.loading = false;
            this.loaded = true;
        });


    },

    methods: {


    }
}
</script>

<style scoped>
.text {
  width: 50%;
  border: 1px solid #ccc;
  height: 20px;
  padding: 12px 20px;
  margin: 4px;
  border-radius: 4px;
}

.profile {
  display: list-item;
  text-align: center;
}

.button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.local-link :hover {
  color: #50b5ff;
}

.local-badge {
  color: #50b5ff;
}

.local-loader {
  margin-top: 2rem;
}

.wrapper {
  padding-bottom: 3rem;
}

</style>