<template>

<div class="wrapper">
    <div id="content-page" class="content-page" >
        <div class="container" v-show="loaded">

            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h3>Acceso VIP</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
            <div class="col-lg-8 row m-0 p-0">

                <div class="col-sm-12" v-for="p in posts" :key="p.id">
                    <div class="card card-block card-stretch card-height">
                    <div class="card-body">
                        <div class="user-post-data">
                            <div class="d-flex justify-content-between">
                                <div class="me-3">
                                    <img class="rounded-circle img-fluid local-avatar" 
                                    v-bind:src="collectionImages[p.slug]" alt=""
                                    v-on:click="collectionLink(p.slug)">
                                </div>
                                <div class="w-100">
                                <div class="d-flex justify-content-between">
                                    <div class="">
                                        <h5 class="mb-0 d-inline-block">{{ p.title }}</h5>
                                        <p class="mb-0 text-primary">{{ p.ts_text }}</p>
                                    </div>

                                    <!-- <div class="card-post-toolbar">
                                        <div class="dropdown">
                                            <span class="dropdown-toggle" id="postdata-5" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                <i class="ri-more-fill"></i>
                                            </span>
                                            <div class="dropdown-menu m-0 p-0" aria-labelledby="postdata-5">
                                            <a class="dropdown-item p-3" href="#">
                                                <div class="d-flex align-items-top">
                                                    <i class="ri-save-line h4"></i>
                                                    <div class="data ms-2">
                                                        <h6>Save Post</h6>
                                                        <p class="mb-0">Add this to your saved items</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a class="dropdown-item p-3" href="#">
                                                <div class="d-flex align-items-top">
                                                    <i class="ri-close-circle-line h4"></i>
                                                    <div class="data ms-2">
                                                        <h6>Hide Post</h6>
                                                        <p class="mb-0">See fewer posts like this.</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a class="dropdown-item p-3" href="#">
                                                <div class="d-flex align-items-top">
                                                    <i class="ri-user-unfollow-line h4"></i>
                                                    <div class="data ms-2">
                                                        <h6>Unfollow User</h6>
                                                        <p class="mb-0">Stop seeing posts but stay friends.</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a class="dropdown-item p-3" href="#">
                                                <div class="d-flex align-items-top">
                                                    <i class="ri-notification-line h4"></i>
                                                    <div class="data ms-2">
                                                        <h6>Notifications</h6>
                                                        <p class="mb-0">Turn on notifications for this post</p>
                                                    </div>
                                                </div>
                                            </a>
                                            </div>
                                        </div>
                                    </div> -->

                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="user-post">
                            <img v-if="p.image1" v-bind:src="p.image1" alt="post-image" class="img-fluid rounded w-100">
                            <img v-if="p.image2" v-bind:src="p.image2" alt="post-image" class="img-fluid rounded w-100">
                        </div>

                        <div class="mt-3">
                            <p v-html="p.description"></p>
                        </div>


                    </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <iq-card>
                    <template v-slot:headerTitle>
                        <!-- <h4 class="card-title">Tus Avatares NFT</h4> -->
                        <div>Este material exclusivo está disponible gracias a que obtuviste los siguientes Avatares NFT:</div>
                    </template>


                    <template v-slot:body>
                        <div v-for="s in ownedSlugs" :key="s">
                            <img class="rounded-circle img-fluid local-collection-avatar"
                            v-bind:src="collectionImages[s]" v-on:click="collectionLink(s)"
                            >
                        </div>
                    </template>
                </iq-card>

            </div>

            <!-- placeholder for advertising -->


            </div>

            <button v-on:click="loadPosts" class="btn btn-primary me-2" v-show="moreButton">
                Cargar más
            </button>
        </div>

        <div v-show="loading">
            <div class="d-flex justify-content-center local-loader">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>



    </div>
</div>


  <!-- <div style="text-align: center">
      VIP Section!!!
  </div>

    <div v-for="p in posts" :key="p.id" >
        * {{  p.title }}<br>
        <img v-if="p.image1" v-bind:src="p.image1" width="300">
        <img v-if="p.image2" v-bind:src="p.image2" width="300">
    </div>

    <button v-on:click="loadPosts">Mas</button> -->

</template>

<script>
/** 
 *  https://firebase.google.com/docs/firestore/query-data/query-cursors
 *  
 * 
 */



import CollectionService from "@/services/CollectionService";
import FirebaseService from "@/services/FirebaseService";
import Web3Service from "@/services/Web3Service";

export default {
    name: "VIPall",
    data() {
        return {
            collectionSlugs: null,
            collectionImages: null,
            ownedSlugs: [],
            collectionSetId: -1,
            collectionSet: null,
            name: "",
            description: "",
            firebase: null,
            posts: null,
            page: 0,
            lastLength: 0,
            moreButton: 1,
            loading: true,
            loaded: false         
        }
    },
    async mounted() {
        console.log("[vip] mounting");

        // validate
        let isCon = await Web3Service.dApp.isConnected();
        if (!isCon) {
            alert("No estas conectado con la billetera ...");
            this.$router.push("/");
        }
        await this.init();
    },

    methods: {
        async init() {
            this.firebase = new FirebaseService();
            let {slugs, images} = CollectionService.main.getCollectionSlugs();
            this.collectionSlugs = slugs;
            this.collectionImages = images;
            // Get logged user
            this.firebase.getLoggedUser(this.validate, this.notLogged);
        },

        notLogged() {
            alert("No estas logueado en el sistema ...");
            this.$router.push("/");
        },

        validate() {
            // Validates if user has NFTs
            this.loadExchangeContract();
            this.exchange.methods.balanceOfWallet(Web3Service.dApp.address).call()
                .then(async (result) => {
                    // Grab NFT collection indexes user owns:
                    let owns = [];
                    for(let i=0; i < result.length; i++) {
                        let elem = parseInt(result[i]);
                        if (elem) {
                            owns.push(i);
                        }
                    }
                    console.log("owns", owns);
                    // Compute slugs
                    for(var i=0; i < owns.length; i++) {
                        let elem = owns[i];
                        let slug = this.collectionSlugs[elem];
                        if (!this.ownedSlugs.includes(slug)) {
                            this.ownedSlugs.push(slug);
                        }
                    }
                    if (this.ownedSlugs.length > 0) {
                        await this.loadPosts();
                    }
                }).catch((error) => {
                    console.log("[vip] validate ", error);
                });
        },

        async loadPosts() {
            this.page += 1;
            this.moreButton = 0;
            this.loading = true;
            await sleep(2000);

            // validate page max???
            this.posts = await this.firebase.getPostsBySlugList(
                this.ownedSlugs, this.page
            );
            if (this.posts.length == this.lastLength) {
                this.moreButton = 0;
            } else {
                this.lastLength = this.posts.length;
                this.moreButton = 1;
            }
            this.loading = false;
            this.loaded = true;
        },

        loadExchangeContract() {
            this.exchange = new Web3Service.dApp.roWeb3.eth.Contract(
                Web3Service.dApp.exchangeAbi, Web3Service.dApp.exchangeAddress
            );
        },

        collectionLink(slug) {
            // decompose slug
            let url = CollectionService.main.decomposeSlug(slug);
            this.$router.push(url);
        }


    }
}


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

</script>

<style scoped>

.local-avatar {
  width: 60px;
  cursor: pointer;
}

.local-collection-avatar {
  width: 48px;
  cursor: pointer;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
}

.local-loader {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.wrapper {
  padding-bottom: 4rem;
}

</style>