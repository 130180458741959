<template>
  <div class="footer">

   <div class="container-fluid local-options">

       <a href="/ayuda">Ayuda</a>

       <a href="/tyc">Términos</a>

       <a href="/privacidad">Privacidad</a>


       <a href="https://twitter.com/avataresnft" target="_blank">
           <i class="fab fa-twitter"></i>
       </a>

       <a href="https://instagram.com/avataresnft" target="_blank">
           <i class="fab fa-instagram"></i>
       </a>

       <a href="mailto:contacto@avatares.io" target="_blank">
        <i class="far fa-envelope"></i>
       </a> 

   </div>



  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  text-align: right;
  padding: 0.8rem;
}
.local-options a {
  margin-right: 1rem;
}
</style>