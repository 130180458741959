<template>

<!-- loader -->

<div class="wrapper">
    <div id="content-page" class="content-page">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                <div class="iq-edit-list-data">
                    <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">Iniciar Sesión</h4>
                    </template>

                    <template v-slot:body>
                        <div class="row align-items-center">
                        
                            <div class="form-group col-lg-12">
                                <label for="email"  class="form-label">Email</label>
                                <a v-on:click="forgotEmail" class="float-end local-forgot">Me olvidé el email!</a>
                                <input type="email" class="form-control" name="email" id="email"
                                 v-model="form.email" @keydown.space.prevent>
                            </div>

                            <div class="form-group col-lg-12">
                                <label for="url"  class="form-label">Clave</label>
                                <a v-on:click="forgotPassword" class="float-end local-forgot">Me olvidé la clave!</a>
                                <input type="password" class="form-control" name="twitter" id="twitter"
                                 v-model="form.password">
                            </div>
                        </div>

                        <div class="spinner-border text-primary" role="status" v-show="loader">
                            <span class="visually-hidden">iniciando ...</span>
                        </div>

                        <button v-show="loginBt" class="btn btn-primary me-2" v-on:click="login">Iniciar</button>
                    </template>

                    </iq-card>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import FirebaseService from "@/services/FirebaseService";
import Web3Service from "@/services/Web3Service";
const Verifier = require("email-verifier");

export default {
    name: "Login",
    data() {
        return {
            user: null,
            form: {
                email: "",
                password: "",
            },
            firebase: null,
            wallet: null,
            profile: null,
            source: null,
            loader: false,
            loginBt: true
        }
    },


    async mounted() {
        this.firebase = new FirebaseService();
        // Check if connected
        let isCon = await Web3Service.dApp.isConnected();
        if (!isCon) {
            alert("not connected!!");
            this.$router.push("/");
        }
        this.wallet = Web3Service.dApp.address;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.mode = urlParams.get("mode");
        this.source = urlParams.get("source");
    },

    methods: {

        login() {
            this.firebase.login(
                this.form.email, this.form.password, this.check
            )
        },

        check(error) {
            if (error) {
                alert(error);
                return;
            }
            if (this.source) {
                this.$router.push(this.source);
            } else {
                this.$router.push("/");
            }
        },

        forgotEmail() {
            console.log("Wallet", this.wallet);
            this.$swal({
                icon: "warning",
                html: "<a href='mailto:contacto@avatares.io?subject=Email%20perdido&body=Mi%20billetera:%20" + this.wallet + "' target='_blank'>Clickea acá</a> para mandarnos tu dirección de billetera y te enviaremos un mensaje al email registrado.",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
            });
        },

        forgotPassword() {
            this.$swal.fire({
                //icon: "info",
                title: "",
                text: "Un momento por favor ...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            });
            let verifier = new Verifier("at_DQjvSoEXghweUeuYsX30AJgUMxz3r");
            verifier.verify(this.form.email, async (err, data) => {
                if (err) {
                    this.$swal({
                        icon: "error",
                        text: "Ingresa un email válido!",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    });                
                    return;
                }
                if (data.dnsCheck == "false") {
                    this.$swal({
                        icon: "error",
                        text: "Dominio inexistente, por favor intenta con otro email",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    });                
                    return;
                }
                if (data.formatCheck == "false") {
                    this.$swal({
                        icon: "error",
                        text: "Email incorrecto, por favor corregir",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    });                
                    return;
                }
                // all good
                this.firebase.resetPassword(this.form.email, this.rpSuccess, this.rpError);
            });

        },

        rpSuccess() {
            this.$swal({
                icon: "success",
                text: "Listo! Recibirás instrucciones en " + this.form.email + " para resetear tu clave",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
            });
            return;            
        },

        rpError() {
            this.$swal({
                icon: "error",
                text: this.form.email + " no existe. Por favor intenta con otro email, o usa el link \"Me olvidé el email\"",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
            });                
            return;            
        }

    }
}
</script>

<style scoped>

.profile-img-edit{
  position: relative;
    display: inline-block;
    height: 150px;
    width: 150px;
}
.profile-pic{
  max-width: 100%;
    display: block;
}
.p-image{
  position: absolute;
    top: auto;
    right: 8px;
    bottom: 10px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background: #50b5ff;
    color: #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}
.file-upload{
  display: none;
}
.upload-button{
  font-size: 1.5em;
}

.local-forgot {
  cursor: pointer;
}
</style>