<template>

<div class="iq-top-navbar">
    <div class="iq-navbar-custom">
        <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
                <router-link to="/">
                    <img :src="image" class="img-fluid" alt="">
                    <span>avatares</span>
                </router-link>
                <!-- <div class="iq-menu-bt align-self-center">
                    <div class="wrapper-menu" @click="sidebarmini">
                        <div class="main-circle"><i class="ri-menu-line"></i></div>
                    </div>
                </div> -->
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-label="Toggle navigation">
                <i class="ri-menu-line"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                <div class="caption ava-menu-info" v-show="connected">
                    <h6>{{ profile.username }}</h6>
                    {{ balance }} MATIC
                </div>

                <ul class="navbar-nav  ms-auto navbar-list">

                    <li v-show="disconnected">
                        <div class="d-flex align-items-center ava-menu" style="visibility: hidden">
                        <!-- <div class="d-flex align-items-center ava-menu" v-on:click="connect"> -->
                            conectar 
                            <i class="ri-wallet-line ava-menu-icon"></i>
                        </div>
                    </li>

                    <li v-show="connected" class="nav-item dropdown">
                        <a v-bind:href="profileLink" class="d-flex align-items-center dropdown-toggle" id="drop-down-arrow">
                            <img v-bind:src="avatar" width="100" class="img-fluid rounded-circle me-3" alt="user">
                        </a>
                    </li>

                </ul>

            </div>
        </nav>
    </div>
</div>

</template>

<script>

import FirebaseService from "@/services/FirebaseService";
import Web3Service from "@/services/Web3Service";

//import { useRouter } from 'vue-router';
//import { computed } from 'vue';

// import router from '@/router'
//import storeProfile from "@/store";

const AVATAR_DEFAULT = "https://firebasestorage.googleapis.com/v0/b/avatares-io.appspot.com/o/avataresv2.png?alt=media&token=066f275d-ffbf-4e49-9f92-0c1ae1d2bf2c";

export default {
    name: 'Header',

    props: {
        image: {
            type: String,
            default: require('@/assets/avataresv3.png')
        }
    },

    data() {
        return {
            profile: false,
            profileLink: "",
            wallet: null,
            firebase: new FirebaseService(),
            balance: 0,
            user: null,
            url: window.location.pathname,
            route: "",
            avatar: "https://firebasestorage.googleapis.com/v0/b/avatares-io.appspot.com/o/placeholder.png?alt=media&token=602cefc8-37d3-407b-8e00-9e5d225ba053",
            connected: false,
            disconnected: false
        }
    },

    async mounted() {
        await this.accHeader();

        // EVENT LISTENERS -------------------------------
        this.emitter.on("header:update:avatar", (url) => {
            this.updateAvatar(url);
        });
        this.emitter.on("header:update:balance", () => {
            this.updateBalance();
        });

    },

    methods: {

        async accHeader() {
            // Check if web3 is installed
            this.connected = await Web3Service.dApp.isConnected();
            console.log("[header] isconnected", this.connected);
            if (this.connected) {
                await this.checkUser();
            } else {
                // Show connect button, hide user info
                console.log("[header] not connected");
                this.disconnected = true;
            }
        },

        async checkUser() {
            this.wallet = Web3Service.dApp.address;
            console.log("[header] Connected with", this.wallet);
            this.connected = true;
            this.disconnected = false;
            // let curPath = window.location.pathname;

            //alert("Hide connect button!");

            // get balance
            await this.updateBalance();

            // Get logged user
            this.firebase.getLoggedUser(this.loggedUser, this.notLoggedUser);

            // if (!user) {
            //     console.log("[header] not logged in");
            //     // profile exists?
            //     let profile = await this.firebase.getProfile(this.wallet);
            //     if (!profile) {
            //         // create profile / log user in
            //         await this.firebase.createProfile(this.wallet);
            //         // try to store user location
            //         try {
            //             this.firebase.getIPInfo(this.firebase.updateUserLoc);
            //         } catch(err) {
            //             console.log("[header] error getting location", err);
            //         }


            //         // redirect to signup
            //     } else {
            //         console.log("update profile");

            //     }
            // } else {
            //     console.log("logged")
            // }

            return;



            // this.user = await this.firebase.queryUser(this.wallet);
            // if (!this.user) {
            //     console.log("[header] not logged in");
            //     // create profile
            //     await this.firebase.createUser(this.wallet);
            //     // try to store user location
            //     try {
            //         this.firebase.getIPInfo(this.wallet, this.firebase.updateUserLoc);
            //     } catch(err) {
            //         console.log("[header] error getting location", err);
            //     }
            //     // redirect to signup
            //     if (curPath != "/perfil/editar") {
            //         this.$router.push("/perfil/editar?source=" + curPath);
            //     }

            // } else {
            //     console.log("[header] logged in", this.user);
            //     // check of profile if empty, suggest ;) 
            //     if (!this.user.username && curPath.startsWith("/perfil/editar")) {
            //         console.log("[header] suggest to continue profile");
            //     }
            //     if (this.user.avatar) {
            //         this.updateAvatar(this.user.avatar);
            //     }
            // }
            // hide/show connect buttons, etc.

        },

        async loggedUser(user) {
            console.log("[header] logged in", user);
            this.user = user;
            if (this.user.photoURL) {
                this.updateAvatar(this.user.photoURL);
            } else {
                this.avatar = AVATAR_DEFAULT;
            }

            this.profile = await this.firebase.getProfile("wallet", this.wallet);
            this.profileLink = "/@" + this.profile.username;
            this.disconnected = false;
            this.connected = true;

            // check profile if empty, suggest ;) 
            // if (!user.username && curPath.startsWith("/perfil/editar")) {
            //     console.log("[header] suggest to continue profile");
            // }

            // hide/show connect buttons, etc.

        },

        async notLoggedUser() {
            console.log("[header] not logged in");
            let curPath = window.location.pathname;
            // profile exists?
            let profile = await this.firebase.getProfile("wallet", this.wallet);

            if (!profile) {
                // redirect to signup
                this.updateAvatar(AVATAR_DEFAULT);
                if (curPath != "/perfil/editar") {
                    this.$router.push("/perfil/editar?source=" + curPath);
                }



                // await this.firebase.createProfile(this.wallet);
                // // try to store user location
                // try {
                //     this.firebase.getIPInfo(this.firebase.updateUserLoc);
                // } catch(err) {
                //     console.log("[header] error getting location", err);
                // }
                // // redirect to signup
                // if (curPath != "/perfil/editar") {
                //     this.$router.push("/perfil/editar?source=" + curPath);
                // }
            } else {
                console.log("get profile and log them in");
                // if they lost the password, polaco can log them in
                // emailing the password (for now manually)
                if (curPath != "/login") {
                    this.$router.push("/login?source=" + curPath);
                }
            }

        },

        async old_connect() {
            try {
                await Web3Service.dApp.connect(this.checkUser);
            } catch (e) {
                console.log(e);
            }
        },

        async goToProfile() {
            await this.$router.push("/perfil");
        },

        async updateBalance() {
            console.log("[header] updating balance");
            this.balance = await Web3Service.dApp.balance();
        },

        updateAvatar(url) {
            console.log("[header] chaging avatar", url);
            this.avatar = url;
        },

        async connect() {
            let hasProvider = await Web3Service.dApp.hasProvider();
            // Encapsulate this to reuse in connect()
            if (!hasProvider) {
                // redirect to mobile dapp browser
                // second line is for iPad since UA is same as desktop
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
                   (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) 
                ) {
                    // mobile device
                    this.$swal({
                        icon: "info",
                        title: "Metamask Mobile!!",
                        text: "Continua tu experiencia en Metamask mobile!",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    })
                    .then(() => {
                        let url = "https://metamask.app.link/dapp/avatares.io/";
                        url += this.url;
                        window.location = url;
                        //await this.$router.push(url);
                    });
                } else {
                    // it's desktop with no metamask installed
                    this.$swal({
                        icon: "error",
                        title: "Instala Metamask!!",
                        text: "Instrucciones para instalar metamask, sacar wallet, etc.",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: true
                    });
                }
            } else {
                try {
                    await Web3Service.dApp.connect(
                        this.checkUser, this.disconnect
                    );
                } catch (e) {
                    console.log(e);
                }

            }
        },

        disconnect() {
            this.connected = false;
            this.disconnected = true;
        }


        // logOut() {
        //     storeProfile.dispatch('resetState')
        // }
    }
}

</script>

<style scoped>
    .header {
        display: flex;
        height: 20px;
        /* background-color: green; */
        color: white;
        padding: 12px 20px;
    }

    .ava-menu {
        font-size: 1rem;
        color: #1976D2;
        cursor: pointer;
        margin-right: 1.2rem;
    }

    .ava-menu-icon {
        margin-left: 0.3rem;
    }

    .ava-menu-info {
        line-height: 1.4rem;
        padding-left: 1rem;
    }
</style>