<template>
  <div class="profile">

    <div> 
        Current:
        <img v-bind:src="userAvatar" />

    </div>


    <div>
      <input id="avatar" type="file" name="avatar" placeholder="Avatar ToDo"
             alt="avatar-img" v-on:change="uploadFile">
    </div>

      <button class="button" v-on:click="done">
        Listo!
      </button>

  </div>
</template>

<script>
// import storeProfile from "@/store";
import FirebaseService from "@/services/FirebaseService";
import ProfileService from "@/services/ProfileService";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB

export default {
    name: "ProfileAvatar",
    data() {
        return {
            user: null,
            userAvatar: null,
            firebase: null,
            wallet: null,
            mode: null,
            source: null
        }
    },

    async mounted() {
        this.firebase = new FirebaseService();
        this.wallet = await ProfileService.getWallet();
        this.user = await this.firebase.getUser(this.wallet);
        this.userAvatar = this.user.avatar;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.mode = urlParams.get("mode");
        this.source = urlParams.get("source");
    },

    methods: {

        uploadFile(event) {
            const file = event.target.files[0];
            // validate size
            let fileSize = file.size;
            if (fileSize > MAX_FILE_SIZE) {
                alert("No more than 1Mb!!!");
                return;
            }
            let filename = this.wallet;
            const storage = getStorage();
            const storageRef = ref(storage, filename);
            uploadBytes(storageRef, file).then(async (snapshot) => {
                // successfully uploaded
                let url = await getDownloadURL(snapshot.ref);
                await this.firebase.updateAvatar(this.wallet, url);
                // update 
                this.userAvatar = url;
            });
        },

        done() {
            if (this.mode == "signup") {
                this.$router.push(this.source);
            } else {
                alert("go to profile summary");
            }

        }

    }
}
</script>

<style scoped>
.text {
  width: 50%;
  border: 1px solid #ccc;
  height: 20px;
  padding: 12px 20px;
  margin: 4px;
  border-radius: 4px;
}

.profile {
  display: list-item;
  text-align: center;
}

.button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
</style>