//import FirebaseService from "@/services/FirebaseService";
// import Web3Service from "@/services/Web3Service";
// Upload cache files to:
// https://console.firebase.google.com/u/0/project/avatares-io/storage/avatares-io.appspot.com/files


const main = {
    // This should be generated automatically from the MD generator
    Avatars: {
        "avatares": {
            "title": "Exclusivos de Avatares",
            "description": "Colecciones exclusivas de Avatares.io",
            "image": "",
            "video": "",
            "collectionSets": [
                {
                    "title": "Herederos del Horror",
                    "avatar": "avatares",
                    "slug": "horror",
                    "postsSlug": "avatares:horror",
                    "description": "Esta primerísima colección piloto de Avatares retrata a los descendientes directos de nuestros personajes favoritos del horror: los tátara,tátara,(10 u 11 veces más...) nietos y nietas del engendro, el vampiro, el zombie y la momia, en su adolescencia.",
                    "collectionSetId": 0,  // collectionSetId from contract
                    "image": "https://firebasestorage.googleapis.com/v0/b/avatares-io.appspot.com/o/avatares%3Ahorror%2Favatares-horror-1.png?alt=media&token=720d5020-e81c-4062-924a-987811a40239",
                        // preview image (the ones with a preview of all avatars)
                    "video": "",
                    "starts": 1651410000,  // 4/16 6:00am PT  // test 1646154000
                    "ends": 9999999999,
                    "editions": 200,  // grand total
                    "collections": [
                        {
                            "id": 0,  // token class from contract
                            "title": "Herederos del Horror: Jorge",
                            "description": "Jorge es descendiente directo del engendro creado allá por los años 1800. Tiene 16 años y concurre a una escuela secundaria técnica. Si bien sus ancestros se inclinaban más por lo gótico, Jorge optó por un estilo skater: anda en patineta, escucha música punk y suele lucir un buzo con capucha, pantalones holgados y zapatillas rotas. Es algo introvertido, le encanta la pizza, los videojuegos de acción en primera persona y de vez en cuando lee historietas de horror. Su aspiración es ser un skater profesional.",
                            "collectionSetId": 0,
                            "image": "https://ipfs.io/ipfs/bafybeihh7f6uiuxfgvvvy4qsdh42rzzmglw6jze7jmuazwrbfpqpybqf6a",
                            "image_cache": "https://bafybeihh7f6uiuxfgvvvy4qsdh42rzzmglw6jze7jmuazwrbfpqpybqf6a.ipfs.nftstorage.link",
                            "video": null,
                        },
                        {
                            "id": 1,
                            "title": "Herederos del Horror: Vanesa",
                            "description": "Vanesa es sucesora de las famosas momias egipcias con más de 90 siglos de legado. Tiene 17 años y está cursando la universidad para ser contadora, pues le encantan los números. Cuando no estudia le gusta escuchar música pospunk por lo que usualmente se viste con atuendos oscuros que contrasten con sus vendas claras. A pesar de su gusto musical, Vanesa es muy simpática, tiene buen sentido del humor, le gustan los gatos y su objetivo es formar una familia y tener su propio estudio contable. Sus pastas favoritas son los tallarines con salsa blanca.",
                            "collectionSetId": 0,
                            "image": "https://ipfs.io/ipfs/bafybeidj55tqwehrlehgxdgnmgfisriyt46btrutc5aryn6fbb2qudasty",
                            "image_cache": "https://bafybeidj55tqwehrlehgxdgnmgfisriyt46btrutc5aryn6fbb2qudasty.ipfs.nftstorage.link",
                            "video": null,
                        },
                        {
                            "id": 2,
                            "title": "Herederos del Horror: Zoe",
                            "description": "Zoe es una zombi muy extrovertida a quien le gusta escuchar música reggae y debido a su herencia vudú, suele vestir atuendos de colores vistosos pero sin incluir el negro, y usualmente con flores o mariposas. Casi siempre se peina con rastas y usa gorros de lana incluso cuando hace calor. Zoe tiene 17 años y cursa en la universidad la carrera de contaduría, pues quiere seguir los pasos de su abuelo. En su tiempo libre le gusta dibujar en lápiz, armar rompecabezas y andar en bicicleta. A Zoe le gusta la cocina japonesa y sus platos favoritos son sushi y ramen.",
                            "collectionSetId": 0,
                            "image": "https://ipfs.io/ipfs/bafybeiaf3tvmms365ti5jju3i75vl7ybnkyxp5y5hcnckp2nba5dhe4xdq",
                            "image_cache": "https://bafybeiaf3tvmms365ti5jju3i75vl7ybnkyxp5y5hcnckp2nba5dhe4xdq.ipfs.nftstorage.link",
                            "video": null,
                        },
                        {
                            "id": 3,
                            "title": "Herederos del Horror: Iván",
                            "description": "Iván pertenece a la familia de los vampiros originales del norte de Rumania, perseguidos desde el siglo XIX. Ivan tiene 17 años, terminó la escuela secundaria y trabaja en una tienda de música trap, ya que es su estilo musical preferido - por lo que casi siempre luce una gorra con la visera para atrás y un collar de oro. Iván está estudiando canto y piano por su cuenta, porque quiere llegar a ser algún día un cantante de trap reconocido en latinoamérica. A diferencia de sus ancestros, su dieta es estrictamente vegetariana y le encantan los pasteles.",
                            "collectionSetId": 0,
                            "image": "https://ipfs.io/ipfs/bafybeiaxpw6tkgmoh3gualetu3oacy5shewt2p3cxboq5iobo5kgg5ibgq",
                            "image_cache": "https://bafybeiaxpw6tkgmoh3gualetu3oacy5shewt2p3cxboq5iobo5kgg5ibgq.ipfs.nftstorage.link",
                            "video": null,
                        }
                    ]
                }
            ]
        }
    },

    getAvatar: (slug) => {
        let avatar = null;
        let slugClean = slug.toLowerCase();
        if (slugClean in main.Avatars) {
            avatar = main.Avatars[slugClean];
        }
        return avatar;
    },

    getCollectionSets: (slug) => {
        let collectionSets = null;
        let slugClean = slug.toLowerCase();
        if (slugClean in main.Avatars) {
            collectionSets = main.Avatars[slugClean].collectionSets;
        }
        return collectionSets;
    },

    getAllCollections: () => {
        let allCollections = [];
        for(const [key, value] of Object.entries(main.Avatars)) {
            console.log(key);
            for(var cs=0; cs < value.collectionSets.length; cs++) {
                allCollections.push(...value.collectionSets[cs].collections);
                // console.log(value.collectionSets[cs].collections);
            }
        }
        return allCollections;
    },

    getCollectionSet: (avatarSlug, setSlug) => {
        // look for collection
        let collectionSets = main.getCollectionSets(avatarSlug);
        let collectionSet = null;

        for (var i=0; i < collectionSets.length; i++) {
            let elem = collectionSets[i];
            if (elem.slug == setSlug) {
                collectionSet = elem;
                break;
            }
        }
        return collectionSet;
    },

    getCollectionSetByIndex: (index) => {
        let collectionSet = null;
        for(const [key, value] of Object.entries(main.Avatars)) {
            console.log(key);
            for(var cs=0; cs < value.collectionSets.length; cs++) {
                let elem = value.collectionSets[cs];
                if (elem.collectionSetId == index) {
                    collectionSet = elem;
                    break;
                }
            }
        }
        return collectionSet;
    },

    getCollectionSlugs: () => {
        let collectionSlugMap = {};
        let collectionImageMap = {};
        for(const [key, value] of Object.entries(main.Avatars)) {
            console.log(key, value);
            for(var cs=0; cs < value.collectionSets.length; cs++) {
                for(var x=0; x < value.collectionSets[cs].collections.length; x++) {
                    // console.log(">>>>", value.collectionSets[cs].collections[x]);
                    let elem = value.collectionSets[cs].collections[x];
                    let slug = value.collectionSets[cs].postsSlug;
                    collectionSlugMap[elem.id] = slug;
                    collectionImageMap[slug] = value.collectionSets[cs].image;
                }
            }
        }
        return {
            "slugs": collectionSlugMap,
            "images": collectionImageMap
        };
    },

    decomposeSlug: (postSlug) => {
        // from postsSlug, e.g. avatares:horror, returns string with URL /avatares/horror
        let parts = postSlug.split(":");
        return "/" + parts[0] + "/" + parts[1];
    }

}

exports.main = main;
