<template>

  <div class="container" style="padding: 1rem">

    <div class="row py-lg-5">

      <div class="col-lg-10 col-md-12 mx-auto">
        <h1 class="fw-light">Términos y Condiciones</h1>

        <h3 class="fw-light">Introducción</h3>
        <p class="lead text-muted">
Estos términos y condiciones de uso ("Condiciones") regulan el uso del sitio web de Avatares ("Plataforma"), así como el uso de tokens no fungibles que hacen referencia a coleccionables digitales ("NFT", "NFTs" por sus siglas en inglés - Non Fungible Tokens) en la Plataforma. La Plataforma es operada por o en nombre de Avatares ("nosotros", "nuestro", "Avatares").<br>
Al utilizar la Plataforma, usted acepta regirse por estas Condiciones. Estas condiciones forman un contrato legalmente vinculante entre usted y nosotros. Si no está de acuerdo con estas Condiciones, no debe acceder ni utilizar la Plataforma. Nos reservamos el derecho de cambiar estas Condiciones de vez en cuando actualizandolas en nuestro sitio web ubicado en https://avatares.io/tyc
        </p>

        <h3 class="fw-light">1. La Plataforma</h3>
        <p class="lead text-muted">
1.1. La Plataforma está construida sobre la red Binance (BSC) y permite a los usuarios comprar NFTs<br>
1.2. Los propietarios de NFTs no poseerán ningún derecho de propiedad intelectual ("PI") sobre el arte digital y/o el motivo al que haga referencia el NFT.<br>
1.3. Cuando compre un NFT, tendrá la posibilidad de transferirlo a otras personas o venderlo a través de mercados compatibles
        </p>

        <h3 class="fw-light">2. Billeteras digitales y criptomonedas</h3>
        <p class="lead text-muted">
2.1. Los usuarios de la Plataforma deben conectar una billetera digital compatible (MetaMask) o navegar a través de un navegador móvil compatible (Metamask u Opera) a nuestra página web para poder comprar un Coleccionable. Podemos actualizar la gama de billeteras compatibles de vez en cuando a nuestra entera discreción.<br>
2.2. Los usuarios sólo podrán comprar utilizando la criptomoneda Binance Coin ("BNB"). Podemos actualizar la criptomoneda con la que se puede comprar NFTs de vez en cuando, a nuestra entera discreción.
        </p>

        <h3 class="fw-light">3. Acceso a la plataforma</h3>
        <p class="lead text-muted">
Al utilizar la Plataforma, usted reconoce y confirma que:
<ul>
<li>Tiene al menos 18 años o es mayor de edad para entablar relaciones contractuales con Avatares.io.</li>
<li>Usted es el beneficiario exclusivo y/o propietario legal de cualquier criptomoneda utilizada o que se utilizará para la compra de un objeto de colección en la Plataforma.</li>
<li>No utilizará nuestro sitio web para llevar a cabo ninguna actividad ilegal o ilícita.</li>
<li>No está asociado con ninguna organización terrorista o ilegal</li>
<li>Es su responsabilidad asegurarse que su equipo (computadora, computadora portátil, tableta u otro dispositivo móvil) cumpla con todas las especificaciones técnicas necesarias para permitirle acceder y usar la Plataforma</li>
<li>Es posible que, de vez en cuando, restrinjamos el acceso a ciertas funciones, partes o contenido de la Plataforma, o de toda la Plataforma, a los usuarios que se hayan registrado con nosotros. Debe asegurarse que todos los detalles de registro que nos proporcione sean correctos. Si elige o se le proporciona un nombre de usuario y contraseña u otro identificador como parte de nuestros procedimientos de seguridad, debe tratar dicha información como confidencial y no deberá revelarla a nadie. Usted será responsable de todas las actividades que se realicen con su nombre de usuario y contraseña y deberá notificarnos de inmediato sobre cualquier uso no autorizado u otra violación de seguridad de la que tenga conocimiento. Nos reservamos el derecho de deshabilitar cualquier nombre de usuario, en cualquier momento, si en nuestra opinión, no ha cumplido con alguna de las disposiciones de estas Condiciones o si algún dato que proporcione para registrarse como usuario resulta ser falso.</li>
<li>Usted puede personalizar su nombre de usuario. Nos reservamos el derecho de cambiarlo por ejemplo si incluye lenguaje ofensivo o infringe las leyes de derechos de autor o marcas comerciales.</li>
</ul>
        </p>


        <h3 class="fw-light">4. Propiedad inicial de los tokens</h3>
        <p class="lead text-muted">
Para cada NFT, Avatares es el propietario inicial. Avatares podrá promocionar y ofrecer cualquier objeto coleccionable a la venta a través de la Plataforma.
        </p>

        <h3 class="fw-light">5. Derechos limitados para propietarios de NFTs</h3>
        <p class="lead text-muted">
5.1. Como propietario de un NFT, usted no puede copiar, modificar, editar o aplicar ingeniería inversa a cualquier parte del mismo, incluidas las imágenes, los colores, el diseño, el aspecto, el formato u otras características o atributos, utilizar el NFT como parte de otra obra, incluida cualquier película, imagen, impresión, obra de audio u otro tipo de medio, someter el NFT a un tratamiento despectivo, utilizar el NFT para cualquier fin comercial, incluido para la publicidad o la promoción de cualquier negocio, producto o servicio, crear cualquier producto comercial, como mercancía, que se refiera o asemeje al NFT, utilizar el NFT (o la Plataforma) para cualquier propósito relacionado con el odio, el racismo, la violencia, el juego, la esclavitud, la intolerancia, la crueldad, las promociones no solicitadas o el acoso, las ventas, el spam, o para cualquier propósito que infrinja los derechos de otros o que sea ilegal de alguna manera, proteger (o pretender proteger) el NFT con cualquier otro derecho de propiedad intelectual como marcas, patentes o diseños registrados.<br>
5.2. No debe eliminar ni ocultar ninguna información en cuanto a la atribución de la creación del NFT, ni tomar ninguna medida que pueda tener el efecto de engañar a otros en cuanto a la identidad de quién creó o licenció el NFT. Usted puede utilizar la Plataforma únicamente con fines no comerciales. No nos hacemos responsables de ninguna pérdida comercial.<br>
5.3. Cualquier beneficio económico que pueda derivarse de la apreciación en el valor del NFT es incidental por obtenerlo para su propósito de colección.
        </p>


        <h3 class="fw-light">6. Acceso a contenido exclusivo ("VIP")</h3>
        <p class="lead text-muted">
6.1. Como propietario de un NFT, usted tendrá acceso a contenido exclusivo que puede incluir mensajes, anuncios, fotos y/o videos.<br> 
6.2. Este contenido solo está disponible en nuestro sitio web y su descarga, copia, reproducción, distribución, venta o cualquier otra forma de uso comercial, digital o físico, son estrictamente prohibidos.<br>  
6.3. Avatares hará todo lo posible para publicar contenido con frecuencia, pero dicha frecuencia no está garantizada.<br>
6.4. El contenido exclusivo de cada colección se ejecutará durante un período de tiempo predeterminado (3, 4, 6 o 12 meses). Avatares hará todo lo posible para publicar dicho contenido durante ese tiempo, pero este período no siempre está garantizado.
        </p>

        <h3 class="fw-light">7. Sorteos</h3>
        <p class="lead text-muted">
7.1. Como propietario de un NFT, usted tendrá derecho a participar en sorteos de premios digitales o físicos que se anunciarán en nuestra web en la zona de contenidos exclusivos de cada colección.<br>
7.2. Sólo pueden participar en el sorteo los mayores de 18 años. Se le puede pedir, en cualquier momento, que proporcione a Avatares una prueba de edad y/o de identidad.<br>
7.3. Los empleados o familiares de los empleados de Avatares y cualquier entidad afiliada o relacionada con Avatares no serán elegibles para participar en el sorteo. "Familiares" es equivalente a esposa/o, concubina/o, padre, madre, hija/o o hermana/o.<br> 
7.4. Avatares notificará al ganador del sorteo por correo electrónico dentro de los diez (10) días hábiles posteriores al final del sorteo.<br>
7.5. Avatares se reserva el derecho de cancelar o volver a realizar el sorteo si el ganador no proporciona una dirección de correo electrónico, una dirección física o no responde al correo electrónico en los dos (2) días siguientes de recibir la notificación.<br>
7.6. La decisión de Avatares es definitiva con respecto a todos los asuntos relacionados con la concesión del premio y no estará sujeta a revisión o apelación por parte de ningún participante ni de ningún tercero.<br>
7.7. Su participación en el sorteo se considera su aceptación de que Avatares pueda utilizar su nombre e imagen con fines promocionales y de marketing sin que se le exija ningún pago, y por la presente concede a Avatares el derecho a hacerlo y renuncia expresamente a cualquier reclamación contra Avatares a este respecto.<br>
7.8. El premio no es transferible ni canjeable y no se puede cambiar por dinero o bienes del mismo valor.<br>
7.9. Al participar en el sorteo, cada participante acepta sin reservas estos términos y condiciones que rigen el sorteo y la entrega del premio.<br>
7.10. Al participar en el Sorteo, cada participante acepta liberar, y eximir de responsabilidad a Avatares, sus representantes legales, filiales, subsidiarias, agencias y sus respectivos funcionarios, directores, empleados y agentes de cualquier daño sufrido, sostenido o supuestamente sostenido en relación con el sorteo y/o la aceptación del premio.<br>
7.11. El participante ganador será el único responsable de cualquier impuesto recaudado en relación con la entrega o recepción del premio.<br>
7.12. En el caso de los premios físicos, Avatares no será responsable del retraso en el envío, la pérdida, el robo o cualquier otro incidente, y no será responsable de las tasas de aduana o los impuestos aplicables en el país donde el ganador reside.<br>
        </p>


        <h3 class="fw-light">8. Protocolos</h3>
        <p class="lead text-muted">
Usted reconoce y comprende que su dirección pública de Binance Smart Chain se hará pública cada vez que participe en una transacción en la Plataforma. La Plataforma puede utilizar otros protocolos descentralizados basados en blockchain en lugar de o además de Binance Smart Chain, o bifurcaciones de Binance Smart Chain u otros protocolos similares. Las referencias en estas Condiciones a la red Binance deben considerarse también como referencias a cualquier otro protocolo que utilicemos.
        </p>


        <h3 class="fw-light">9. Comprando NFTs</h3>
        <p class="lead text-muted">
9.1. Los NFTs pueden comprarse en la Plataforma a través de nuestra propia interfaz de venta.<br>
9.2. Las colecciones de NFTs pueden contener dos o más tipos diferentes de NFTs y, a través del proceso de compra, la plataforma entregará un tipo aleatorio el cual Avatares ni ninguna otra entidad tiene control. Usted no podrá elegir el tipo de NFT y no podrá reclamar el tipo que le tocó. Todas las ventas y entregas son finales y no tendrá derecho a reclamos.
        </p>

        <h3 class="fw-light">10. Flujo de fondos</h3>
        <p class="lead text-muted">
10.1. La Plataforma proporciona a los usuarios una interfaz conveniente que permite a los usuarios interactuar directamente con un blockchain. La Plataforma o Avatares no almacenan ni manejan criptomonedas en ninguna instancia en el proceso.<br>
10.2. Usted reconoce y entiende que los contratos inteligentes no otorgan a Avatares la custodia, posesión o control de ningún NFT o criptomoneda en ningún momento con el fin de facilitar las transacciones. Usted afirma que es consciente y reconoce que la Plataforma no tiene carácter de custodia y que la Plataforma ha sido diseñada para que los compradores puedan acceder directamente a ella sin ninguna participación o acción tomada por Avatares. Nunca custodiamos la criptomoneda de ningún usuario y no somos un proveedor de servicios de pago.<br>
10.3. Usted reconoce que al comprar un objeto de colección, debe pagar las tarifas de gas en Binance Smart Chain además del precio del coleccionable. Estas tarifas de gas se utilizan para recompensar a los mineros quienes garantizan que el NFT y la criptomoneda para pagarlo se transfieran de manera segura. Avatares no tiene control sobre el precio del gas ni sobre los mineros.
        </p>

        <h3 class="fw-light">11. Impuestos y otras tarifas</h3>
        <p class="lead text-muted">
Usted es responsable de contabilizar y pagar todos los impuestos y/o tarifas aplicables para el uso de la Plataforma y la compra de NFTs, si corresponde.
        </p>

 
        <h3 class="fw-light">12. Asunción de riesgo</h3>
        <p class="lead text-muted">
Usted acepta y reconoce que:
<ul>
<li>No podemos garantizar el funcionamiento continuo, ininterrumpido o sin errores de la Plataforma. Puede haber ocasiones en las que determinadas funciones, partes o contenidos de la Plataforma, o la Plataforma en su totalidad, no estén disponibles (ya sea de manera programada o no) o sean modificadas, suspendidas o retiradas por nosotros, a nuestra entera discreción, sin previo aviso. Usted acepta que no seremos responsables ante usted ni ante ningún tercero por la falta de disponibilidad, modificación, o suspensión de la Plataforma, ni de ninguna característica, parte o contenido de la Plataforma.</li>
<li>Las criptomonedas y NFTs no están respaldados por ningún gobierno, banco central, valor físico u otra garantía. Sus valores y liquidez pueden fluctuar sustancialmente con ascensos y descensos a la actividad y el sentimiento del mercado, el sentimiento público y regulador, la legislación, las medidas regulatorias y gubernamentales adoptadas en relación con los criptoactivos en general, o específicamente, y otros factores fuera del control de los usuarios y/o de Avatares.</li>
<li>Las dificultades tecnológicas experimentadas por la Plataforma o los sistemas relacionados/afiliados pueden afectar el acceso a sus NFTs.</li>
<li>Usted comprende completamente y puede permitirse asumir los riesgos de comprar NFTs, usted es el único responsable de determinar el nivel de riesgo involucrado y todo el uso de la Plataforma es bajo su propio riesgo. No somos responsables de las pérdidas que pueda sufrir como resultado de una caída en el valor u otros aspectos de la compra de cualquier NFT, incluida la interrupción temporal o permanente del acceso al contenido exclusivo.</li>
<li>Las tecnologías blockchain y los NFTs están sujetos a muchas incertidumbres legales y regulatorias, y la Plataforma o los NFTs podrían verse afectados negativamente por una o más consultas, acciones, demandas, investigaciones, reclamos, multas o juicios regulatorios o legales, que podrían impedir o limitar su capacidad para continuar el uso de dichos activos digitales y tecnologías.</li>
<li>No poseemos ni controlamos la red Binance Smart Chain (ni ninguna otra red aplicable compatible con la Plataforma), ningún proveedor de billetera digital ni ningún otro proveedor de infraestructura externo utilizado en relación con la Plataforma, y no nos hacemos responsables de ningún defecto, falta de disponibilidad, actos u omisiones de dicha infraestructura de terceros.</li>
<li>La red Binance Smart Chain puede estar sujeta a cambios repentinos en las reglas de operación, y terceros pueden, de vez en cuando, bifurcar el blockchain e implementar cambios en las reglas de operación u otras características que pueden resultar en varias versiones de Binance Smart Chain y, posiblemente, más de una versión de NFTs. Esto puede afectar el valor y la función del NFT inicial y podemos suspender temporalmente nuestros servicios mientras determinamos, a nuestro exclusivo y absoluto criterio, qué red admitir. Dichas redes y bifurcaciones están fuera de nuestro control y podemos decidir, a nuestra única discreción, abandonar o no admitir la copia inicial o la segunda copia del NFT. Podemos, a nuestro exclusivo criterio, obtener y retener los NFT no compatibles.</li>
<li>Existen riesgos asociados con la compra de NFTs basados en blockchain, que incluyen, entre otros, el riesgo de perder claves privadas o frases semilla, el robo de criptomonedas o NFTs debido a que los piratas informáticos descubren su clave privada o frase semilla. Usted es el único responsable de guardar la clave privada o la frase semilla asociada con la dirección del blockchain utilizada para comprar NFTs. Usted acepta que Avatares no podrá restaurar o emitir ningún reembolso con respecto a ningún NFT debido a la pérdida o el robo de las claves privadas o de la frase semilla. Si no puede gastar o utilizar un NFT debido a la pérdida o el robo de la clave privada o frase semilla correspondiente o de otro modo, no podrá ejercer sus derechos con respecto a dicho NFT. Consulte los detalles en la Plataforma en cuanto a los procesos implementados para ayudarlo (que quedan a nuestra discreción).</li>
<li>La Plataforma no almacena, envía ni recibe NFTs. Los NFT se acuñan y transfieren en el contrato inteligente implementado por Avatares en la red de Binance. Cualquier transferencia de criptoactivos se produce a través del contrato inteligente ubicado en Binance Smart Chain y no en la Plataforma.</li>
<li>Avatares no se hace responsable de los objetos de colección creados en la Plataforma. Avatares no investiga ni puede garantizar la autenticidad, la originalidad, la singularidad, la comerciabilidad, la legalidad o el valor de cualquier NFT creado o comercializado en la Plataforma o de la obra de arte subyacente.</li>
<li>Avatares no será responsable de ningún error, tergiversación u omisión en, o en torno al NFT, ni de la disponibilidad del mismo. Avatares no será responsable de las pérdidas, lesiones o daños derivados de la compra, la imposibilidad de comprar, mostrar o usar el NFT.</li>
<li>Existe el riesgo de que Avatares se vea obligado a retirar listados de la Plataforma si ha recibido un Aviso de Derechos de Autor del Milenio Digital ("Aviso DMCA"). Avatares también puede estar obligado a entregar un Aviso de DMCA a su proveedor de servicios de IPFS. Si esto ocurre, es posible que el NFT que representa el objeto de colección ya no haga referencia a la obra de arte. Los NFTs vinculados a imágenes sujetas a un Aviso de la DMCA pueden hacer referencia posteriormente a una ubicación de archivo no válida o a un archivo que ya no existe.</li>
</ul>
Las acusaciones de que se está infringiendo otro derecho de propiedad intelectual deberán enviarse a contacto@avatares.io. Avatares puede, a su entera discreción, eliminar de la lista NFTs de aquellos acusados de infracción de derechos de autor u otros derechos de propiedad intelectual.
        </p>

        <h3 class="fw-light">13. El rol de Avatares</h3>
        <p class="lead text-muted">
13.1. Nuestra función se limita a proporcionar una plataforma que permita a los usuarios comprar NFTs.<br>
13.2. Hacemos todo lo posible para asegurarnos de que todos los NFTs sean obras originales y no infrinjan ningún derecho de terceros. No se puede garantizar ni asegurar la singularidad, originalidad o calidad de ningún objeto de colección. Usted no tiene ningún recurso o derecho contra nosotros en relación con la existencia de obras similares o idénticas, o en relación con la infracción de cualquier derecho de terceros.<br>
13.3. Hacemos todo lo posible por ofrecer contenidos exclusivos y organizar sorteos para cada colección de NFTs y respetar lo programado, no existe garantía de obtener dichos contenidos autorizados por el creador del NFT y/o el licenciante.<br> 
13.4. Usted se compromete a indemnizarnos y a nuestras filiales, así como a nuestros directores y funcionarios, por todas las pérdidas incurridas en relación con cualquier reclamación, responsabilidad, pérdida o daño que surja de (o se alegue en relación con) cualquier uso que usted haga de la Plataforma, el comercio de NFTs, o cualquier incumplimiento de estas Condiciones por su parte.<br>
        </p>


        <h3 class="fw-light">14. Uso prohibido de la Plataforma</h3>
        <p class="lead text-muted">
14.1. Salvo en la medida en que se establezca expresamente en estas Condiciones, no está permitido: extraer contenido o almacenar contenidos de la Plataforma en un servidor u otro dispositivo de almacenamiento conectado a una red o crear una base de datos descargando y almacenando sistemáticamente todo el contenido de la Plataforma, eliminar o modificar cualquier contenido de la Plataforma o intentar burlar la seguridad o interferir en el correcto funcionamiento de la Plataforma o de los servidores en los que está alojada, o crear enlaces a la Plataforma desde cualquier otro sitio web, sin nuestro consentimiento previo por escrito. Sin embargo, usted puede establecer un enlace desde un sitio web operado por usted, siempre que el enlace no sea engañoso o equívoco e indique de forma justa su destino, que no dé a entender que lo respaldamos a usted, a su sitio web o a cualquier producto o servicio que ofrezca, que enlace (y no enmarque o reproduzca) la página de inicio de la Plataforma, y que el sitio web enlazado no contenga ningún contenido ilegal, amenazante, abusivo, difamatorio, pornográfico, obsceno, vulgar, indecente, ofensivo, racista o que infrinja los derechos de propiedad intelectual u otros derechos de cualquier tercero.<br>
14.2. Usted sólo puede utilizar la Plataforma para fines lícitos, de forma responsable y que no dañe nuestro nombre o reputación o el de cualquiera de nuestras filiales, directores o funcionarios, o cualquiera de los creadores o licenciantes. Avatares se reserva el derecho de cancelar, suspender o restringir su acceso a la Plataforma si existe una sospecha razonable de que el NFT se ha utilizado o se utilizará para fines ilegales, fraudulentos o no autorizados.<br> 
14.3. Todos los derechos que se le otorgan en virtud de estas Condiciones terminarán de inmediato en caso de que se incumpla alguno de ellos, y no podrá utilizar la Plataforma posteriormente.
        </p>

        <h3 class="fw-light">15. Derechos de propiedad intelectual</h3>
        <p class="lead text-muted">
Todos los derechos de propiedad intelectual de cualquier contenido de la Plataforma (incluidos texto, gráficos, software, fotografías y otras imágenes, vídeos, sonido, marcas comerciales y logotipos) son de nuestra propiedad o de nuestros licenciantes. Salvo lo que aquí se establece expresamente, nada de lo dispuesto en estas Condiciones le otorga a usted ningún derecho respecto a la propiedad intelectual que nos pertenece o a nuestros licenciantes, y usted reconoce que no adquiere ningún derecho de propiedad por descargar contenidos de la Plataforma.
        </p>


        <h3 class="fw-light">16. Responsabilidad</h3>
        <p class="lead text-muted">
16.1. Podemos cambiar el formato y el contenido de la Plataforma de vez en cuando. Usted acepta que el uso que hace de la Plataforma es "tal cual" y "como está disponible" y bajo su propio riesgo. Aunque intentamos asegurarnos que toda la información contenida en la Plataforma es correcta, no pretende ser una autoridad o un consejo en el que se deba confiar. Debe consultar con nosotros o con la fuente de información relevante antes de actuar sobre dicha información. No hacemos ni damos ninguna representación o garantía en cuanto a la exactitud, integridad, actualidad, corrección, fiabilidad, calidad, disponibilidad, adecuación al propósito u originalidad de cualquier contenido de la Plataforma y, en la mayor medida permitida por la ley, todas las garantías implícitas, condiciones u otros términos implícitos de cualquier tipo quedan excluidos y no aceptamos ninguna responsabilidad por cualquier pérdida o daño de cualquier tipo incurrido como resultado de que usted o cualquier otra persona utilice la Plataforma o se base en cualquiera de sus contenidos.<br>
16.2. No podemos garantizar y no garantizamos que ningún contenido de la Plataforma esté libre de virus y/u otro código que pueda tener elementos contaminantes o destructivos. Es su responsabilidad implementar protecciones de seguridad tecnológicas adecuadas (incluidas las comprobaciones de seguridad antivirus y de otros tipos) para satisfacer sus requisitos particulares en cuanto a la seguridad y confiabilidad del contenido.<br>
16.3. En ningún caso seremos responsables ante usted de ninguna pérdida, ya sea indirecta o consecuente, ni de ninguna pérdida de beneficios, ingresos, contratos, datos, fondo de comercio u otras pérdidas similares, y cualquier responsabilidad que tengamos por las pérdidas que sufra se limita estrictamente a las pérdidas que sean razonablemente previsibles.<br>
16.4. No nos hacemos responsables de ningún error del usuario, datos incorrectos o pérdida de NFT u otra información. Los coleccionables son activos intangibles representados por el NFT correspondiente en la red Binance Smart Chain. No somos responsables de esta red, de registrar o verificar la propiedad o el valor de ningún objeto de colección.<br>
16.5. No obstante lo anterior, en caso de que seamos responsables ante usted por cualquier reclamo que surja de estas Condiciones o en relación con la Plataforma, nuestra responsabilidad se limitará a U$D 1,000. Cuando Avatares sea el vendedor por ser propietario inicial del NFT, nuestra responsabilidad se limitará al precio de venta pagado por esa venta inicial.
        </p>


        <h3 class="fw-light">17. Su información personal</h3>
        <p class="lead text-muted">
Usted nos otorga su consentimiento para que accedamos, procesemos y conservemos cualquier información personal que nos proporcione con el fin de que operemos la Plataforma. Este consentimiento no está relacionado ni afecta a ningún derecho u obligación que tengamos nosotros o usted de acuerdo con las leyes de protección de datos, las leyes de privacidad y las regulaciones. Consulte nuestra Política de privacidad disponible en https://avatares.io para obtener más información sobre cómo procesamos su información personal y los derechos que tiene al respecto.
        </p>


        <h3 class="fw-light">18. Enlaces externos</h3>
        <p class="lead text-muted">
La Plataforma puede, de vez en cuando, incluir enlaces a sitios externos, ofertas de terceros y promociones. Los incluimos para brindarle acceso a información, productos o servicios que pueden resultarle útiles o interesantes. No nos hacemos responsables del contenido de estos sitios ni de lo que proporcionen y no garantizamos que estén disponibles continuamente. El hecho que incluyamos enlaces a dichos sitios externos no implica ningún tipo de respaldo o asociación con sus operadores o promotores.
        </p>


        <h3 class="fw-light">19. Ley aplicable y jurisdicción</h3>
        <p class="lead text-muted">
19.1. Estas Condiciones se rigen por la ley inglesa, excepto que puede haber ciertas leyes obligatorias aplicables de su país o región que se aplican para su beneficio y protección además de o en lugar de ciertas disposiciones de la ley inglesa.<br>
19.2. Usted acepta que cualquier disputa entre usted y nosotros en relación con estas Condiciones o la Plataforma sólo será tratada por los tribunales ingleses, salvo que si usted vive en un país de la Unión Europea distinto de Inglaterra, puede optar por iniciar un procedimiento judicial en su país o en Inglaterra, pero si nosotros iniciamos un procedimiento judicial, podemos optar por hacerlo en su país.
        </p>


        <h3 class="fw-light">20. Información de contacto</h3>
        <p class="lead text-muted">
Por favor envíe cualquier pregunta que tenga sobre estas Condiciones o cualquier problema relacionado con la Plataforma por correo electrónico a
<a href="mailto:contacto@avatares.io">contacto@avatares.io</a>
        </p>


      </div>
    </div>

  </div>

</template>

<script>
export default {
    name: 'Terms',
    data() {
        return {}
    },
    mounted() {
        console.log("[home] mounting");
    },

    methods: {
    }
}
</script>

<style scoped>

</style>
