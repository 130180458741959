<template>

  <div class="container" style="padding: 1rem; margin-bottom: 3rem;">

    <div class="row py-lg-5">

      <div class="col-lg-10 col-md-12 mx-auto">

        <h4 class="fw-light">
            <a href="/ayuda">Ayuda</a> > Comprar <span class="text-muted">(PC)</span>
        </h4>


        <h1 class="fw-light">
            Como comprar desde tu PC
        </h1>

        <h3 class="fw-light">Sumario</h3>
        <ol>
            <li>Crea tu billetera con Metamask</li>
            <li>Compra MATIC</li>
            <li>Accede al sitio</li>
            <li>Conecta la billetera</li>
            <li>Compra el NFT</li>    
        </ol>

        <h3 class="fw-light">1. Crea tu billetera con Metamask</h3>
        <h6 class="text-muted">Si ya tenés una billetera de criptomonedas Metamask en tu navegador,
            podés saltear al paso 2.</h6>

        <p class="lead">
            Para poder comprar avatares NFTs en Avatares.io, primero hay que tener una billetera de criptomonedas
            en dónde almacenar la criptomoneda para poder pagar por el NFT (en este caso BNB) y también para almacenar
            el NFT una vez comprado.<br>
            Por el momento, Avatares.io solo soporta navegadores 
            <a href="https://google.com/chrome/" target="_blank">Chrome</a> y 
            <a href="https://brave.com/download/" target="_blank">Brave</a> junto con la extensión Metamask (independientemente del sistema operativo, 
            que puede ser Windows o Mac OSX).<br>
            Para instalar la extensión de Metamask:
        </p>
        <ul>
            <li>Ir al sitio de <a href="https://metamask.io/download/" target="_blank">Metamask</a></li>
            <li><b>CUIDADO</b> con instalarlo de alguna otra fuente, sólo instalarlo si viene de metamask.io</li>
            <li>Después de instalada la extensión, estará la opción de crear una nueva billetera, o importar
                si se creó una antes
            </li>
            <li>Si ya se creó una antes, ingresar la frase secreta (las 12 palabras en inglés)
            </li>
            <li>Si se crea una billetera nueva, anotar en un lugar seguro la frase secreta (las 12 palabras en inglés)
            </li>
            <li>En lo posible anotar la frase secreta en un papel, NUNCA en un medio digital (email, documento, etc.)
            </li>
            <li><b>MUY IMPORTANTE!</b>: 
                NO DAR LA FRASE SECRETA A NADIE! Ni a un soporte de Metamask, Avatares, o cualquier otro sitio,
                a nadie!! Ya que si das la frase secreta tendrán acceso a tu billetera y podrán sacarte
                todas las criptomonedas y los NFTs. Reiteramos, a nadie!
            </li>
            <li>
                Se pueden seguir estas instrucciones:<br><br>
                <iframe width="80%" height="320" src="https://www.youtube.com/embed/Lwmx_DO77_M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <br><br>
            </li>

        </ul>

        <h3 class="fw-light">2. Compra MATIC</h3>
        <p class="lead">
            Los avatares NFT de Avatares.io están en la red Polygon y se deben pagar con MATIC. 
            Dependiendo del país donde estés se puede comprar MATIC con tarjeta de crédito o con transferencia
            bancaria.
            <ul>
                <li><b>Con TARJETA DE CRÉDITO:</b> se puede comprar directamente en
                    <a href="https://transak.com" target="_blank">Transak</a>.<br>
                    También se puede comprar en
                    <a href="https://www.binance.com/es/buy-sell-crypto" target="_blank">Binance</a> pero
                    hay que comprar USDT primero, y luego con USDT se compra MATIC y por último se transfiere
                    el MATIC a tu billetera Metamask. Se puede seguir este tutorial paso a paso:<br><br>
                    <iframe width="80%" height="320"  src="https://www.youtube.com/embed/rq08SPlwvoM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <br><br>
                </li>
                <li><b>Con TRANSFERENCIA BANCARIA:</b>
                    Primero se debe depositar desde tu banco a Binance, luego comprar USDT, con USDT comprar MATIC y por último
                    se transfiere el MATIC a tu billetera Metamask. Para depositar de tu banco a Binance, se pueden seguir estas
                    instrucciones:<br><br>
                    <iframe width="80%" height="320" src="https://www.youtube.com/embed/oRWOS7NJBH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <br><br> 
                </li>
            </ul>
        </p>

    
        <h3 class="fw-light">3. Accede al sitio</h3>
    
        <p class="lead">
            Para poder comprar desde el móvil se debe acceder a Avatares.io desde Chrome o Brave, simplemente
            ingresando <b>avatares.io</b> en la barra de dirección.
        </p>

        <h3 class="fw-light">4. Conecta tu billetera</h3>
        <p class="lead">
            Ahora hay que conectar la billetera antes de poder comprar. Desde la página:
        </p>
        <ul>
            <li>Hacer click en el link "Conectar" que está arriba a la derecha
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuydesk1.png" />

            <li>Metamask te va a preguntar qué billetera usar para conectarse a Avatares - apretar "Siguiente":
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuydesk2.png" />

            <li>Confirmar que deseas conectarte a Avatares - apretar "Conectar":
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuydesk3.png" />

            <li>Metamask te puede llegar a preguntar si deseas agregar la red Polygon a Metamask, a lo cual
                hay que apretar en "Agregar"
            </li>

            <li>Tambiên te puede llegar a preguntar de cambiar a la red Polygon, ahi hay que apretar
                "Cambiar red".</li>

            <li>Si es la primera vez que ingresas al sitio, te va a preguntar por nombre de usuario,
                email, tu cuenta de Twitter y tu cuenta de Instagram
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuydesk4.png" />

            <li>Sólo el nombre de usuario y email son obligatorios - los demás campos son opcionales
            </li>
        </ul>

        <h3 class="fw-light">5. Compra el NFT</h3>
        <p class="lead">
            Una vez conectada tu billetera, la cual ya tiene MATIC, ya está todo listo para comprar!
        </p>
        <ul>
            <li>Ir a la colección que se desea comprar
            </li>
            <li>Leer y chequear los "términos de uso"
            </li>
            <li>Tocar "Comprar Avatar"
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuydesk5.png" />
            <li>La extensión Metamask preguntará por autorización para transferir el precio del NFT en MATIC -
                apretar "Confirmar":
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuydesk6.png" />

            <li>Se generará un avatar NFT de la colección al azar, será transferido a tu billetera y se revelará
                qué te tocó!
            </li>
            <img style="width: 80%; margin-bottom: 1.5rem;" src="@/assets/images/metabuydesk7.png" />

            <li>Cabe destacar que estarás comprando una sola unidad del avatar NFT, y que el NFT 
                será generado al azar dentro de las opciones que la colección ofrece
            </li>
            <li>El avatar NFT generado no se puede cambiar: el que toca, toca; y no se puede predecir
                cuál va a ser generado
            </li>
            <li>El NFT no tendrá token ID, pertenecerá a una de las clases de la colección
            </li>    
        </ul>

        <p>Para más preguntas por favor 
            <a href="mailto:contacto@avatares.io" target="_blank">contactarse con nosotros</a>.
        </p>
        

      </div>
    </div>

  </div>

</template>

<script>
export default {
    name: "HelpBuyDesk",
    data() {
        return {}
    },
    mounted() {
        console.log("[home] mounting");
    },

    methods: {
    }
}
</script>

<style scoped>

</style>
