// Imports
const Web3Modal = require("web3modal").default;
const Web3 = require("web3");
const detectEthereumProvider = require("@metamask/detect-provider");
// import detectEthereumProvider from "@metamask/detect-provider";

//const WalletConnectProvider = require("@walletconnect/web3-provider");
// const fb = require("./fb");

// https://pretagteam.com/question/how-to-detectlaunch-and-connect-to-metamask-mobile-app-by-clicking-on-a-button-on-the-webapp-like-on-opensea

/**
Network Name: Smart Chain
New RPC URL: https://bsc-dataseed.binance.org/
ChainID: 56 (0x38)
Symbol: BNB
Block Explorer URL: https://bscscan.com
*/

const exchangeABI = () => {
    return [
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "_factoryAddress",
              "type": "address"
            },
            {
              "internalType": "address payable",
              "name": "_marketAddress",
              "type": "address"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "previousOwner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "OwnershipTransferred",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "bytes32",
              "name": "role",
              "type": "bytes32"
            },
            {
              "indexed": true,
              "internalType": "bytes32",
              "name": "previousAdminRole",
              "type": "bytes32"
            },
            {
              "indexed": true,
              "internalType": "bytes32",
              "name": "newAdminRole",
              "type": "bytes32"
            }
          ],
          "name": "RoleAdminChanged",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "bytes32",
              "name": "role",
              "type": "bytes32"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "account",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "sender",
              "type": "address"
            }
          ],
          "name": "RoleGranted",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "bytes32",
              "name": "role",
              "type": "bytes32"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "account",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "sender",
              "type": "address"
            }
          ],
          "name": "RoleRevoked",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "collectionId",
              "type": "uint256"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "price",
              "type": "uint256"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "Sold",
          "type": "event"
        },
        {
          "inputs": [],
          "name": "DEFAULT_ADMIN_ROLE",
          "outputs": [
            {
              "internalType": "bytes32",
              "name": "",
              "type": "bytes32"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [],
          "name": "factory",
          "outputs": [
            {
              "internalType": "contract Factory",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [
            {
              "internalType": "bytes32",
              "name": "role",
              "type": "bytes32"
            }
          ],
          "name": "getRoleAdmin",
          "outputs": [
            {
              "internalType": "bytes32",
              "name": "",
              "type": "bytes32"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [
            {
              "internalType": "bytes32",
              "name": "role",
              "type": "bytes32"
            },
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "grantRole",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes32",
              "name": "role",
              "type": "bytes32"
            },
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "hasRole",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [],
          "name": "marketAddress",
          "outputs": [
            {
              "internalType": "address payable",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            },
            {
              "internalType": "uint256[]",
              "name": "",
              "type": "uint256[]"
            },
            {
              "internalType": "uint256[]",
              "name": "",
              "type": "uint256[]"
            },
            {
              "internalType": "bytes",
              "name": "",
              "type": "bytes"
            }
          ],
          "name": "onERC1155BatchReceived",
          "outputs": [
            {
              "internalType": "bytes4",
              "name": "",
              "type": "bytes4"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            },
            {
              "internalType": "bytes",
              "name": "",
              "type": "bytes"
            }
          ],
          "name": "onERC1155Received",
          "outputs": [
            {
              "internalType": "bytes4",
              "name": "",
              "type": "bytes4"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "owner",
          "outputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [],
          "name": "renounceOwnership",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes32",
              "name": "role",
              "type": "bytes32"
            },
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "renounceRole",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bytes32",
              "name": "role",
              "type": "bytes32"
            },
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "revokeRole",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "transferOwnership",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "_collectionSetId",
              "type": "uint256"
            }
          ],
          "name": "buy",
          "outputs": [],
          "stateMutability": "payable",
          "type": "function",
          "payable": true
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "_collectionSetId",
              "type": "uint256"
            }
          ],
          "name": "available",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "_collectionSetId",
              "type": "uint256"
            }
          ],
          "name": "price",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "_account",
              "type": "address"
            }
          ],
          "name": "balanceOfWallet",
          "outputs": [
            {
              "internalType": "uint256[]",
              "name": "",
              "type": "uint256[]"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [
            {
              "internalType": "bytes4",
              "name": "interfaceId",
              "type": "bytes4"
            }
          ],
          "name": "supportsInterface",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        }
    ];
}

const switchToPoly = async (callback) => {
    let ethereum = window.ethereum;
    console.log("[web3] switching to Polygon");
    const data = [{
        chainId: "0x13881",  // testnet 
        chainName: "Mumbai",
        // chainId: '0x89',  // mainnet 137 
        // chainName: 'Matic',
        nativeCurrency:
            {
                // name: 'tMATIC',
                // symbol: 'tMATIC',
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18
            },
        // Testnet
        rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com"],
        // Main
        // rpcUrls: ['https://rpc-mainnet.matic.network'],
        // blockExplorerUrls: ['https://polygonscan.com/'],
    }]
    /* eslint-disable */
    const tx = ethereum.request({
        method: 'wallet_addEthereumChain', params: data
    })
    .then(() => {
        if (callback) {
            callback();
        }
    })
    .catch((error) => {
        console.log("ERROR")
    });
}

const dApp = {

    rightChain: 80001,  // Mumbai, change for Polygon for prod
    web3: null,     // Metamask web3
    roWeb3: new Web3(
        new Web3.providers.WebsocketProvider(
            "wss://speedy-nodes-nyc.moralis.io/09564582dcd7cfb89775d2c4/polygon/mumbai/ws"
            // "wss://speedy-nodes-nyc.moralis.io/09564582dcd7cfb89775d2c4/bsc/testnet/ws"

        )
        // new Web3.providers.WebsocketProvider(
        //     "wss://rinkeby.infura.io/ws/v3/61928ffe2a9a41308e224103a76beebb"
        // )
    ),
    address: null,  // connected address
    roFlag: 0,      // read only flag
    exchangeAbi: exchangeABI(),
    exchangeAddress: "0xA5c733657d9eABE9Cc9Cb9122AD3E1a51D487916",
    factoryAddress: "0x162D0Fbe860953BC8804C81822c728c12712f37E",
    metadata: "bafybeifa4rtpjn5jwdlhn2ojm2exszea6a77qtrkjcjmy2ppgifay5jyku",

    hasWeb3Installed: async() => {
        console.log("[w3] checking web3");
        // if (typeof web3 == 'undefined') {
        //     return false;  // returns undefined, which means no web3 installed
        // }
        return true;
    },

    hasProvider: async() => {
        const provider = await detectEthereumProvider();
        if (provider) {
            return true;
        } else {
            return false;
        }
    },

    isConnected: async () => {
        console.log("[w3] checking connection");
        try {
            let ww3 = new Web3(window.ethereum);
            let adds = await ww3.eth.getAccounts();
            if (adds.length > 0) {
                dApp.address = adds[0];
                dApp.web3 = ww3;
                return true;
            } else {
                return false;
            }
        } catch(err) {
            console.log("[w3] web3 error", err);
            return false;
        }
    },

    balance: async () => {
        console.log("[w3] balance");
        const chainId = await dApp.web3.eth.getChainId();
        let balance = await dApp.web3.eth.getBalance(dApp.address);
        let full = dApp.web3.utils.fromWei(balance, "ether");
        return parseFloat(full).toFixed(4);
    },

    connect: async (callbackDefault, callbackDisconnect) => {
        const providerOptions = {
            // walletconnect: {
            //     package: WalletConnectProvider,
            //     options: {
            //         infuraId: "f7be000c41be49d8beeaedb718474df5",
            //     }
            // }
        };

        const web3Modal = new Web3Modal({
            //network: "rinkeby", // optional
            cacheProvider: false, // optional
            providerOptions // required
        });

        const provider = await web3Modal.connect();
    
        // Subscribe to accounts change
        provider.on("accountsChanged", (accounts) => {
            console.log("Account changed", accounts);
            if (!accounts.length && callbackDisconnect) {
                callbackDisconnect();
            }
        });
    
        // Subscribe to chainId change
        provider.on("chainChanged", async (nChainId) => {
            console.log("[web3] chainChanged");    
            if (nChainId != this.rightChain) {
                await switchToPoly();

            }
            if (callbackDefault) {
                callbackDefault();
            }
        });
    
        // Subscribe to provider connection
        provider.on("connect", (info) => {
            console.log("[web3] connect");
            console.log("[]Connected", info.chainId);
            if (callbackDefault) {
                callbackDefault();
            }
        });
    
        // Subscribe to provider disconnection
        provider.on("disconnect", (error) => {
            console.log("Disconnected", error);
        });
    
        dApp.web3 = new Web3(provider);
        const chainId = await dApp.web3.eth.getChainId();
        console.log(chainId);
        if (chainId != this.rightChain) {
            await switchToPoly();
            console.log("chain changed");
        }

        await dApp.web3.eth.getAccounts((error, accounts) => {
            if (error) throw error;
            dApp.address = accounts[0];
            if (callbackDefault) {
                callbackDefault();
            }
        });
    },

    abbreviateWallet: (wallet) => {
        return wallet.substring(0, 5) + "..." + wallet.substring(wallet.length - 4, wallet.length);
    },

    sign: async (messagePrompt, callback) => {
        /** Executes callback function which should have a dictionary as arg */
        if (!messagePrompt) {
            messagePrompt = "Sign to watch the event (there is no cost!)";
        }

        const domainType = [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" }
        ];
        const metaTransactionType = [
            { name: "permission", type: "string" }
        ];
        const chainId = await dApp.web3.eth.getChainId();
        let domainData = {
            name: "Avatares.io",
            version: "1",
            chainId : chainId,
            verifyingContract: dApp.exchangeAddress
        };
        
        let message = {
            permission: messagePrompt
        };
        
        const dataToSign = JSON.stringify({
            types: {
                EIP712Domain: domainType,
                MetaTransaction: metaTransactionType
            },
            domain: domainData,
            primaryType: "MetaTransaction",
            message: message
        });

        console.log(dataToSign);
        
        web3.currentProvider.sendAsync({
            method: "eth_signTypedData_v4",
            params: [dApp.address, dataToSign],
            from: dApp.address
        }, async function(err, result) {
            if (err) {
                console.log(err);
                callback(null);
                return;
            }
            const signature = result.result.substring(2);
            const r = "0x" + signature.substring(0, 64);
            const s = "0x" + signature.substring(64, 128);
            const v = parseInt(signature.substring(128, 130), 16);

            // Debug
            console.log(signature);
            console.log(r);
            console.log(s);
            console.log(v);
            if (callback) {
                callback({"r": r, "s": s, "v": v});
            }
        });
    }

}

exports.dApp = dApp;
exports.switchToPoly = switchToPoly;
