<template>

<div></div>

</template>

<script>
/** 
 *  https://firebase.google.com/docs/firestore/query-data/query-cursors
 *  
 * 
 */



import CollectionService from "@/services/CollectionService";
import FirebaseService from "@/services/FirebaseService";
import Web3Service from "@/services/Web3Service";

export default {
    name: "VIP",
    data() {
        return {
            avatarSlug: null,
            collectionSetSlug: null,
            collectionSetId: -1,
            collectionSet: null,
            name: "",
            description: "",
            firebase: null,
            posts: null,
            page: 0,
            lastLength: 0
        }
    },
    async mounted() {
        console.log("[vip] mounting");

        // validate
        let isCon = await Web3Service.dApp.isConnected();
        if (!isCon) {
            alert("not connected!!");
            this.$router.push("/");
        }
        await this.init();
    },

    methods: {
        async init() {
            this.avatarSlug = this.$route.params.avatar;
            this.collectionSetSlug = this.$route.params.collection;
            console.log("avatarslug", this.avatarSlug);
            console.log("collectionsetslug", this.collectionSetSlug);

            if (!this.collectionSetSlug) {
                alert("redirect to 404!!!");
                return;
            }
            this.collectionSet= CollectionService.main.getCollectionSet(
                this.avatarSlug, this.collectionSetSlug
            );

            this.firebase = new FirebaseService();
            // Validate if user owns any of the NFT sets
            this.validate();
        },

        validate() {
            // Validates if user has this NFT
            this.loadExchangeContract();
            this.exchange.methods.balanceOfWallet(Web3Service.dApp.address).call()
                .then(async (result) => {
                    // Grab NFT collection indexes user owns:
                    let owns = [];
                    for(let i=0; i < result.length; i++) {
                        let elem = parseInt(result[i]);
                        if (elem) {
                            owns.push(i);
                        }
                    }
                    console.log("owns", owns);
                    // Grab collection indexes for this set:
                    let fromCol = [];
                    for(let i=0; i < this.collectionSet.collections.length; i++) {
                        let elem = this.collectionSet.collections[i];
                        fromCol.push(elem.id);
                    }
                    console.log("coll", fromCol);
                    // Intersection
                    const interS = owns.filter(value => fromCol.includes(value));
                    if (interS.length > 0) {
                        console.log("[vip] loading posts");
                        await this.loadPosts();
                    } else {
                        alert("Not allowed!!");
                    }

                }).catch((error) => {
                    console.log("[vip] validate ", error);
                });

        },

        async loadPosts() {
            this.page += 1;
            // validate page max???
            this.posts = await this.firebase.getPosts(
                this.collectionSet.postsSlug,
                this.page
            );
            if (this.posts.length == this.lastLength) {
                alert("hide more button!");
            } else {
                this.lastLength = this.posts.length;
            }
        },

        loadExchangeContract() {
            this.exchange = new Web3Service.dApp.roWeb3.eth.Contract(
                Web3Service.dApp.exchangeAbi, Web3Service.dApp.exchangeAddress
            );
        },



    }
}
</script>

<style scoped>

</style>