<template>
  <Header></Header>
      <router-view/>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
    components: {Header, Footer},  // ,Footer
    created() {
    }
}
</script>

<style lang="scss">
    @import "./assets/scss/socialv.scss";
    @import url("./plugins/icons/remixicon/fonts/remixicon.css");
    @import url("./plugins/icons/line-awesome/dist/line-awesome/css/line-awesome.min.css");
    @import url("./plugins/icons/@fortawesome/fontawesome-free/css/all.min.css");

    #app {
        background: #fff;
        background-image: url("./assets/images/fondo5b.jpg");
        background-repeat: repeat-x;
        min-height: 940px;
    }

</style>