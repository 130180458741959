import { initializeApp } from "firebase/app"
import { 
    getFirestore, setDoc, doc, getDoc, updateDoc, collection, query, where, getDocs,
    orderBy, limit, addDoc//, startAt
} from "firebase/firestore";
import { 
    getAuth, createUserWithEmailAndPassword, onAuthStateChanged, updateEmail,
    updateProfile, EmailAuthProvider, reauthenticateWithCredential, sendPasswordResetEmail,
    signOut, signInWithEmailAndPassword
} from "firebase/auth";
import { getStorage, ref } from "firebase/storage";



// collection, query, where, getDocs,
//import { getFirestore, getDocs } from "firebase/firestore";

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
// import storeProfile from "@/store";

export default class FirebaseService {
    constructor() {
        console.log("[fb] initializing fb");
        let TEST = process.env.TEST;
        console.log("[fb] key", TEST);

        this.app = initializeApp({
            apiKey: "AIzaSyA65OwUotquiz2kXkIiy8yNFZCIznPYPrU",
            authDomain: "avatares-io.firebaseapp.com",
            projectId: "avatares-io",
            storageBucket: "avatares-io.appspot.com",
            messagingSenderId: "859434022095",
            appId: "1:859434022095:web:0cd6c44b09b2382d3cb3ba",
            measurementId: "G-TLB2V0EHZL"
        });
        this.db = getFirestore();
        console.log("[fb] initialized");
    }

    // async profileExists(wallet) {
    //     const q = query(
    //         collection(this.db, "users"),
    //         where("wallet", "==", wallet)
    //     );
    //     const querySnapshot = await getDocs(q);
    //     let profileId = null;
    //     if (!querySnapshot.empty) {
    //         querySnapshot.forEach((doc) => {
    //             profileId = doc.id;
    //         });
    //     }
    //     return profileId;
    // }

    // legacy =================================================
    // async getUser(wallet) {
    //     console.log("[fb] getUser")
    //     const docRef = doc(this.db, "users", wallet);
    //     const docSnap = await getDoc(docRef);
    //     let userData = null;
    //     if (docSnap.exists()) {
    //         userData = docSnap.data();
    //         const auth = getAuth();
    //         await signInAnonymously(auth);
    //     }
    //     return userData;
    // }

    async getProfile(field, value) {
        console.log("[fb] getProfile", field, value);
        const docRef = collection(this.db, "profiles");
        const q = query(docRef, where(field, "==", value));
        let results = null;
        try {
            let snap = await getDocs(q);
            if (!snap.empty) {
                // should be only 1, otherwise returns last
                snap.forEach((doc) => {
                    results = doc.data();
                });

            }
        } catch(error) {
            console.log("[fb] getProfile error", error);
        }
        return results;
    }

    getLoggedUser(cbLogged, cbNotLogged) {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                cbLogged(user);
            } else {
                cbNotLogged();
            }
        });
    }

    async getMeta(uid) {
        const docRef = doc(this.db, "meta", uid);
        const docSnap = await getDoc(docRef);
        let output = null;
        if (docSnap.exists()) {
            output = docSnap.data();
        }
        return output;       
    }

    async createProfile(wallet, email, username, twitter, instagram) {
        // First create auth user
        let errorMsg = null;
        let auth = getAuth()
        console.log("[fb] creating auth user");
        //let tmpEmail = wallet + "@avatares.io";
        let password = Math.random().toString(36);
        let emaill = email.toLowerCase();
        try {
            await createUserWithEmailAndPassword(auth, emaill, password);
        } catch(error) {
            errorMsg = "Algo raro pasó... por favor intenta nuevamente";
            if (String(error).includes("email-already-in-use")) {
                errorMsg = "Ese email ya fue utilizado!";
            }
            return errorMsg;
        }
        let user = auth.currentUser;
        if (!user) {
            throw "Cannot create user";
        }
        // Create profile
        console.log("[fb] creating profile");
        await setDoc(doc(this.db, "profiles", user.uid), {
            wallet: wallet,
            twitter: twitter,
            instagram: instagram,
            username: username.toLowerCase(),
        });
        // Store meta (private)
        await setDoc(doc(this.db, "meta", user.uid), {
            ip: "",
            lat: "",
            lng: "",
            country: "",
            tmpPassword: password
        });

    }

    // legacy ====================================
    // async createUser(wallet) {
    //     // Creates an empty user
    //     console.log("[fb] creating user");
    //     await setDoc(doc(this.db, "users", wallet), {
    //         avatar: "",
    //         username: "",
    //         wallet: wallet,
    //         twitter: "",
    //         instagram: "",
    //         ip: "",
    //         lat: "",
    //         lng: "",
    //         country: ""
    //     });
    //     const auth = getAuth();
    //     await signInAnonymously(auth);
    // }

    async updateUserLoc(locDict) {
        console.log("[fb] updating user location");
        let thisDb = getFirestore();
        let auth = getAuth();
        let user = auth.currentUser;
        const docRef = doc(thisDb, "meta", user.uid);
        console.log(locDict);
        await updateDoc(docRef, {
            ip: locDict.ip,
            lat: String(locDict.latitude),
            lng: String(locDict.longitude),
            country: String(locDict.country_name),
        });
    }

    async usernameExists(username, wallet) {
        // check if username exists
        const q = query(
            collection(this.db, "profiles"),
            where("username", "==", username)
        );
        const querySnapshot = await getDocs(q);
        let exists = false;
        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                console.log(doc.data().wallet, doc.data().wallet != wallet);
                if (doc.data().wallet != wallet) {
                    exists = true;
                }
            });
        }
        return exists;
    }

    async updateUserProfile(wallet, username, email, twitter, instagram) {
        console.log("[fb] updating profile", wallet, username);
        const auth = getAuth();
        let usern = username.toLowerCase();
        // // check if username exists
        // const q = query(
        //     collection(this.db, "profiles"),
        //     where("username", "==", usern)
        // );
        // const querySnapshot = await getDocs(q);
        // let errorMsg = null;
        // if (!querySnapshot.empty) {
        //     querySnapshot.forEach((doc) => {
        //         console.log(doc.data().wallet, doc.data().wallet != wallet);
        //         if (doc.data().wallet != wallet) {
        //             errorMsg = usern + " está tomado!";
        //         }
        //     });
        // }

        // TODO: check collection set slugs + routes (perfil, etc.)
        let errorMsg = null;
        if (!errorMsg) {
            let user = auth.currentUser;
            // update
            const userRef = doc(this.db, "profiles", user.uid);
            await updateDoc(userRef, {
                username: usern,
                twitter: twitter,
                instagram: instagram
            });
            // this is disabled for now
            if (email) {
                // update user email
                // will need to reauthenticate
                let meta = await this.getMeta(user.uid);
                let credential = EmailAuthProvider.credential(
                    user.email, meta.tmpPassword
                );
                // Now you can use that to reauthenticate
                await reauthenticateWithCredential(user, credential);
                await updateEmail(user, email);
            }
        }
        return errorMsg;
    }

    async updateAvatar(url) {
        console.log("[fb] updating avatar");
        const auth = getAuth();
        let user = auth.currentUser;
        await updateProfile(user, {photoURL: url});
        // Update in local DB
        console.log("updating profile", user.uid);
        const userRef = doc(this.db, "profiles", user.uid);
        let a = await updateDoc(userRef, {avatar: url});
        console.log(a);
        // const userRef = doc(this.db, "users", wallet);
        // await updateDoc(userRef, {avatar: url});
    }

    getRef(filename) {
        const storage = getStorage();
        return ref(storage, filename);  
    }

    async popData() {

        console.log("populating data");
        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345800,
            ts_text: "Jue 2 de Diciembre",
            title: "Mensaje 10 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345700,
            ts_text: "Mie 1 de Diciembre",
            title: "Mensaje 9 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345600,
            ts_text: "Mar 30 de Noviembre",
            title: "Mensaje 8 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345500,
            ts_text: "Lun 29 de Noviembre",
            title: "Mensaje 7 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345400,
            ts_text: "Dom 28 de Noviembre",
            title: "Mensaje 6 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345300,
            ts_text: "Sab 27 de Noviembre",
            title: "Mensaje 5 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345200,
            ts_text: "Vie 26 de Noviembre",
            title: "Mensaje 4 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345150,
            ts_text: "Jue 25 de Noviembre",
            title: "Mensaje 3 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345140,
            ts_text: "Mie 24 de Noviembre",
            title: "Mensaje 2 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

        await addDoc(collection(this.db, "posts"), {
            slug: "avatares:horror",
            ts: 1638345130,
            ts_text: "Mar 23 de Noviembre",
            title: "Mensaje 1 de la ultratumba ",
            description: "<b>WTF</b>",
            image1: "https://testmac1.s3.sa-east-1.amazonaws.com/ronaldo.png",
        });

    }

    async getPosts(slug, page) {
        // Get VIP posts:
        let pageSize = 3;
        let quantity = page * pageSize;
        console.log("[fb] getting posts", slug, page, quantity);

        const q = query(
            collection(this.db, "posts"),
            where("slug", "==", slug),
            orderBy("ts", "desc"),
            limit(quantity)
        );
        const querySnapshot = await getDocs(q);

        let posts = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
//          console.log(doc.id, " => ", doc.data());
            posts.push(doc.data());
        });
        return posts;

        // // let starting = ((page - 1) * pageSize) + 1;
        // //console.log("starting", starting);
        // const q = query(
        //     collection(this.db, "posts"),
        //     where("slug", "==", slug),
        //     orderBy("ts", "desc"),
        //     limit(pageSize),
        //     startAt(0)
        // );
        // const querySnapshot = await getDocs(q);
        // console.log("caca", querySnapshot);
        // querySnapshot.forEach((doc) => {
        //   // doc.data() is never undefined for query doc snapshots
        //   console.log(doc.id, " => ", doc.data());
        // });
    }

    async getPostsBySlugList(slugs, page) {
        // Get VIP posts:
        let pageSize = 5;
        let quantity = page * pageSize;
        console.log("[fb] getting posts", slugs, page, quantity);

        const q = query(
            collection(this.db, "posts"),
            where("slug", "in", slugs),
            orderBy("ts", "desc"),
            limit(quantity)
        );
        const querySnapshot = await getDocs(q);

        let posts = [];
        querySnapshot.forEach((doc) => {
            posts.push(doc.data());
        });
        return posts;
    }

    getIPInfo(callback) {
        const https = require("https");
        const options = {
            hostname: "json.geoiplookup.io",
            port: 443,
            path: "/",
            method: "GET"
        }
        const req = https.request(options, res => {
            res.on("data", (d) => {
                let output = JSON.parse(d.toString());
                callback(output);
            });
        });
        req.on("error", (error) => {
            console.log("[fb] IP error", error);
        });
        req.end();
    }

    resetPassword(email, cbSuccess, cbError) {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
        .then(() => {
            console.log("Email sent!");
            if (cbSuccess) {
                cbSuccess();
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            if (cbError) {
                cbError();
            }
        });        
    }
    
    hashAddress(address) {
        var hash = 0, i, chr;
        if (address.length === 0) return hash;
        for (i = 0; i < address.length; i++) {
            chr = address.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return String(Math.abs(hash));
    }

    login(email, password, callback) {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                // Signed in 
                if (callback) {
                    callback();
                }    
            })
            .catch((error) => {
                if (callback) {
                    callback(error.message);
                }    
            });
    }

    signout(callback) {
        const auth = getAuth();
        signOut(auth).then(() => {
            if (callback) {
                callback(null);
            }
        }).catch((error) => {
            if (callback) {
                callback(error);
            }
        });
    }

}
