//import FirebaseService from "@/services/FirebaseService";
import Web3Service from "@/services/Web3Service";

export default class ProfileService {
    static async checkForm(form) {

        // let fb = new FirebaseService();
        // let errors = [];

        // if (form.username) {
        //     let available = false;

        //     if (form.username.length > 3 && form.username.length <= 30) {
        //         available = await fb.checkUsernameAvailable(form.username);
        //         if (!available) {
        //             errors.push('Username Unavailable');
        //         }
        //     } else {
        //         errors.push('Username need to have at less 3 letters and no more then 30 letters');
        //     }
        // }

        // if (form.email && !this.validEmail(form.email)) {
        //     errors.push('Invalid E-mail');
        // }

        // return errors;

        console.log(form);
        return true;
    }

    validEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    static async getWallet() {
        console.log("[profServ]");
        let isConn = await Web3Service.dApp.isConnected();
        let wallet = null;
        if (isConn) {
            wallet = Web3Service.dApp.address;
        } 
        return wallet;
    }
}
